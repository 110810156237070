<template>
  <div class="d-flex">
<!--    &lt;!&ndash; Показ товаров &ndash;&gt;-->
<!--    <v-btn-->
<!--      v-if="hasProducts"-->
<!--      @click.stop="$emit('show-products', item.id)"-->
<!--      class="mr-2"-->
<!--      small-->
<!--      depressed-->
<!--    >-->
<!--      Товары-->
<!--    </v-btn>-->

    <!-- Кнопка автоматической привязки дочерних категорий -->
    <VariantApprovingDialog
        v-if="mergeMethod === catalogMergeMethods.recursive"
        :for-category="item"
    ></VariantApprovingDialog>

    <!-- Иконки состояния привязки -->
    <div class="mr-2">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            :color="iconColor"
            v-on="on"
            class="mr-1"
            v-text="iconText"
          ></v-icon>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </div>

    <!-- Кнопка выбора -->
    <div @click.stop>
      <v-btn @click="selectItem(item)" :color="buttonColor" small depressed>
        {{ buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as catalogMergeMethods from '@/common/catalogMergeMethods'
import VariantApprovingDialog from './VariantApprovingDialog'

const processingMethodTitles = {
  [catalogMergeMethods.recursive]: 'Создать',
  [catalogMergeMethods.as_single]: 'Объединить'
}

const processingMethods = [
  catalogMergeMethods.recursive,
  catalogMergeMethods.as_single
]

export default {
  components: {
    VariantApprovingDialog
  },
  props: {
    item: Object,
    selectedId: Number,
    selectItem: Function
  },
  data: () => ({
    catalogMergeMethods,
  }),
  computed: {
    ...mapGetters('distributor_categories', ['getDistributorCategoryTestator']),
    // Наследодатель
    testator: function() {
      return this.item.parentItem
        ? this.getDistributorCategoryTestator(this.item.parentItem)
        : null
    },

    mergeMethod: function() {
      return this.item.merge_method
    },

    // Цвет иконки привязки к ассортименту
    iconColor: function() {
      return (this.item.merge_method === catalogMergeMethods.inherit &&
        this.item.bind_structure) ||
        processingMethods.includes(this.item.merge_method)
        ? 'success'
        : 'grey darken-1'
    },
    // Иконка привязки
    iconText: function() {
      if (processingMethods.includes(this.item.merge_method)) {
        return 'mdi-link-box-variant'
      }
      if (
        this.testator &&
        this.item.merge_method === catalogMergeMethods.inherit
      ) {
        return 'mdi-file-tree'
      }
      return 'mdi-close-box-outline'
    },
    // Текст тултипа иконки привязки к ассортименту
    tooltipText: function() {
      if (processingMethods.includes(this.item.merge_method)) {
        return `Обработка: ${processingMethodTitles[this.item.merge_method]}`
      }
      if (
        this.testator &&
        this.item.merge_method === catalogMergeMethods.inherit
      ) {
        return `Наследованно: ${
          processingMethodTitles[this.testator.merge_method]
        }`
      }
      return 'Не обрабатывать'
    },
    // Цвет кнопки выбора
    buttonColor: function() {
      return this.selectedId === this.item.id ? 'success' : 'primary'
    },
    // Текст кнопки выбора
    buttonText: function() {
      return this.selectedId === this.item.id ? 'Выбрано' : 'Выбрать'
    },
    hasProducts: function() {
      return this.item.products_count > 0
    },
  }
}
</script>

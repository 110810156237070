<template>
  <StepSkeleton :loading="cardLoading">
    <!-- Автоматическая привязка -->
    <template #toolbar-left>
      <v-btn @click="autobindRequest" depressed small>
        Автоматическая привязка
      </v-btn>
      <v-dialog v-model="autobindDialog" width="300" persistent>
        <v-card color="primary" dark>
          <v-card-text class="pa-3">
            <div v-if="autobindingRequestInProgress">
              Обработка товаров
              <v-progress-linear
                color="white"
                class="mb-0"
                indeterminate
              ></v-progress-linear>
            </div>
            <div v-else class="d-flex">
              <div>Привязано товаров: {{ autobindResult }}</div>
              <v-btn
                @click.stop="closeAutobindDialog"
                color="success"
                class="ml-auto"
                x-small
                depressed
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <!-- Помощь -->
    <template #help-message>
      <div class="title pb-1">Привязка товаров</div>
      <div class="pb-6">
        Необязательный шаг т.к. привязка по артикулу и создание несуществующих
        товаров проходит автоматически поздно вечером на основе настроек
        привязки каталогов. Но если очень хочется, то можно связать товары,
        создать товары или поменять привязку.
      </div>

      <div class="title pb-1">Автоматическое связывание товаров</div>
      <img src="@/assets/images/help/product_autobind.png" class="mb-1" />
      <div class="pb-6">
        Кнопка запуска автоматической связки товаров по артикулу.
      </div>

      <div class="title pb-1">Список товаров поставщика</div>
      <img src="@/assets/images/help/product_distributor.png" class="mb-2" />
      <ol class="pb-6">
        <li>Фильтр по активности и привязке</li>
        <li>Переключатель активности товара</li>
        <li>
          Иконка наличия привязки товара (зеленый - привязан, серый - не
          привязан)
        </li>
        <li>Кнопка выбора элемента для настройки привязки</li>
      </ol>

      <div class="title pb-1">Настройки привязки</div>
      <img src="@/assets/images/help/product_settings.png" class="mb-2" />
      <ol class="pb-6">
        <li>Создание товара на основе товара поставщика.</li>
        <li>Текущая привязка</li>
        <li>Возможные элементы привязки</li>
        <li>Ссылка на расширенный поиск</li>
        <li>
          Изменения не сохраняются автоматически, нужно обязательно жать кнопку
          "Сохранить изменения"
        </li>
      </ol>

      <div class="title pb-1">Поиск товаров</div>
      <img src="@/assets/images/help/product_search.png" class="mb-2" />
      <ol class="pb-6">
        <li>
          Быстрое копирование артикула, названия или бренда в строку поиска
        </li>
        <li>Строка поиска товаров</li>
        <li>Результаты поиска</li>
        <li>
          "Продолжить" - возвращение к настройкам привязки и установка
          выбранного элемента в качестве цели привязки.
        </li>
      </ol>
    </template>

    <!-- Левая часть -->
    <template #content-left>
      <!-- Выбранный элемент -->
      <div v-if="selectedItem" class="pb-6">
        <div class="subtitle-1">Выбранный товар поставщика:</div>
        <DistributorProductTable
          :items="selectedItemList"
          :onSelect="onSelect"
          :selectedItem="selectedItem"
        ></DistributorProductTable>
        <v-divider></v-divider>
      </div>

      <!-- Фильтр -->
      <v-checkbox
        v-model="filterShowDisabled"
        label="Показывать неактивные"
        class="mt-0"
        hide-details
      ></v-checkbox>

      <div class="mt-4 mb-2">
        <v-btn-toggle
          v-model="filterBinding"
          class="d-flex flex-column flex-md-row"
          mandatory
          borderless
          tile
        >
          <v-btn
            :value="filters.unbinded"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Непривязанные ({{ getDistributorCountUnbindedProducts }})
          </v-btn>
          <v-btn
            :value="filters.binded"
            class="flex-grow-1"
            color="success"
            outlined
            small
          >
            Привязанные ({{ getDistributorCountBindedProducts }})
          </v-btn>
          <v-btn
            :value="filters.all"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Все ({{ getDistributorCountTotalProducts }})
          </v-btn>
        </v-btn-toggle>
      </div>

      <DistributorProductTable
        :items="getDistributorProducts"
        :loading="getDistributorProductsFetching"
        :onSelect="onSelect"
        :itemsPerPage="itemsPerPage"
        :selectedItem="selectedItem"
      ></DistributorProductTable>

      <v-divider></v-divider>

      <div v-if="pageCount" class="text-center pt-4">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="10"
        ></v-pagination>
      </div>
    </template>

    <!-- Правая часть -->
    <template #content-right>
      <Settings
        :selectedItem="selectedItem"
        :fetchDistributorProducts="fetchDistributorProducts"
      />
    </template>

    <template #actions>
      <v-void />
    </template>
  </StepSkeleton>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import distributorProductApi from '@/services/distributorProductsApi'
import StepSkeleton from '../StepSkeleton'
import DistributorProductTable from './DistributorProductTable'
import Settings from './Settings'

const filters = {
  unbinded: 'unbinded',
  binded: 'binded',
  all: 'all'
}

export default {
  components: {
    StepSkeleton,
    DistributorProductTable,
    Settings
  },
  created() {
    this.filters = filters
    // Загрузка параметров при переходе по табам
    if (this.activeDistributor) {
      this.fetchInitialData()
    }
  },
  destroyed() {
    this.actionClearDistributorBrands()
    this.actionClearDistributorProducts()
    this.actionClearStockProducts()
    this.actionClearStockProductsSearch()
    this.actionClearStockBrands()
  },
  data: () => ({
    filterBinding: filters.unbinded,
    filterShowDisabled: false,
    selectedItemId: null,
    page: 1,
    autobindDialog: false,
    autobindResult: 0,
    autobindingRequestInProgress: false,
    itemsPerPage: 10,
  }),
  watch: {
    // Загрузка товаров при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.fetchInitialData()
      }
    },
    computedFilters: function(newData, oldData) {
      if (newData.filterBinding !== oldData.filterBinding) {
        this.page = 1
      }
      this.fetchDistributorProducts()
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_products', [
      'getDistributorProducts',
      'getDistributorCountBindedProducts',
      'getDistributorCountUnbindedProducts',
      'getDistributorCountTotalProducts',
      'getDistributorProductsFetching',
      'getDistributorProductById',
      'getDistributorProductsByIdList',
      'getDistributorProductsUpdating'
    ]),
    ...mapGetters('stock_products', [
      'getStockProductsSuggestFetching',
      'getStockProductsFetching',
      'getStockProductsSearchFetching'
    ]),
    ...mapGetters('distributor_brands', ['getDistributorBrandsFetching']),
    ...mapGetters('stock_brands', ['getStockBrandsFetching']),
    // Вспомогательный элемент для отслеживания одновременно всех фильтров
    computedFilters: function() {
      return {
        filterBinding: this.filterBinding,
        showDisabled: this.filterShowDisabled,
        page: this.page
      }
    },
    selectedItem: function() {
      return this.selectedItemId
        ? this.getDistributorProductById(this.selectedItemId)
        : null
    },
    // Количество страниц
    pageCount: function() {
      let itemsCount = 0
      switch (this.filterBinding) {
        case this.filters.binded:
          itemsCount = this.getDistributorCountBindedProducts
          break
        case this.filters.unbinded:
          itemsCount = this.getDistributorCountUnbindedProducts
          break
        case this.filters.all:
          itemsCount = this.getDistributorCountTotalProducts
          break
      }
      return Math.ceil(itemsCount / this.itemsPerPage)
    },
    // Список с выбранным элементом для построения отдельной таблицы
    selectedItemList: function() {
      return this.selectedItem ? [this.selectedItem] : []
    },
    cardLoading: function() {
      return (
        this.getDistributorProductsFetching ||
        this.getStockProductsSuggestFetching ||
        this.getStockProductsFetching ||
        this.getStockProductsSearchFetching ||
        this.getStockBrandsFetching ||
        this.getDistributorProductsUpdating ||
        this.getDistributorBrandsFetching
      )
    }
  },
  methods: {
    ...mapActions('distributor_products', [
      'actionFetchDistributorProducts',
      'actionClearDistributorProducts',
      'actionFetchDistributorProductsStats'
    ]),
    ...mapActions('stock_products', [
      'actionClearStockProducts',
      'actionClearStockProductsSearch'
    ]),
    ...mapActions('distributor_brands', [
      'actionFetchDistributorBrands',
      'actionClearDistributorBrands'
    ]),
    ...mapActions('stock_brands', [
      'actionClearStockBrands',
      'actionFetchStockBrands'
    ]),
    ...mapActions('app_aggregator', [
      'actionRefreshDistributorUnbindedCounters'
    ]),
    fetchInitialData: function() {
      this.actionFetchDistributorBrands(this.activeDistributor)
      this.actionFetchStockBrands()
      this.fetchDistributorProducts()
    },
    fetchDistributorProducts: function() {
      const params = {
        distributor: this.activeDistributor,
        enabled: true,
        only_mergeable: true,
        page: this.page,
        items_per_page: this.itemsPerPage
      }
      if (this.filterShowDisabled) {
        params['enabled'] = false
      }
      switch (this.filterBinding) {
        case filters.unbinded:
          params['binded'] = false
          break
        case filters.binded:
          params['binded'] = true
          break
      }
      this.actionFetchDistributorProducts(params)
      this.actionFetchDistributorProductsStats({
        id: this.activeDistributor,
        enabled: !this.filterShowDisabled
      })
      this.actionRefreshDistributorUnbindedCounters()
    },
    // Выбор элемента
    onSelect: function(item) {
      this.selectedItemId = item && item.id ? item.id : null
    },
    // Автопривязка
    autobindRequest: function() {
      this.openAutobindDialog()
      this.autobindingRequestInProgress = true
      distributorProductApi
        .autobindDistributorProducts(this.activeDistributor)
        .then(data => {
          this.autobindResult =
            data && data.count && data.count > 0 ? data.count : 0
          if (this.autobindResult) {
            this.page = 1
            this.selectedItemId = null
            this.actionClearDistributorProducts()
            this.fetchDistributorProducts()
          }
        })
        .finally(() => {
          this.autobindingRequestInProgress = false
        })
    },
    openAutobindDialog: function() {
      this.autobindDialog = true
    },
    closeAutobindDialog: function() {
      this.autobindDialog = false
      this.autobindResult = 0
    }
  }
}
</script>

<template>
  <StepSkeleton>
    <!-- Шапка -->
    <template #toolbar-left>
      <h3 style="color: white;">Фильтрация элементов для ускорения синхронизации</h3>
    </template>

    <!-- Помощь -->
    <template #help-message>
      <div class="title pb-1">Выбор категорий</div>
      <div class="pb-1">
        Необходимо выбрать только нужные категории поставщика. На следующих
        вкладках будут отображаться данные только для выбранных категорий.
      </div>
      <ul>
        <li>
          Активные элементы и элементы с активными вложенными элементами
          подсвечиваются голубым цветом.
        </li>
      </ul>
    </template>

    <!-- Контент -->
    <template #content>
      <div class="subtitle-1 font-weight-medium">Тип каталога:</div>

      <v-radio-group
        v-model="selectedCatalogType"
        class="my-0 py-0 pb-4"
        hide-details
        dense
      >
        <v-radio
          :key="catalogTypes.distributor"
          :value="catalogTypes.distributor"
        >
          <template #label>
            <span class="body-2">Папки поставщика</span>
          </template>
        </v-radio>
        <v-radio :key="catalogTypes.structure" :value="catalogTypes.structure">
          <template #label>
            <span class="body-2">Папки нашей номенклатуры</span>
          </template>
        </v-radio>
        <v-radio :key="catalogTypes.category" :value="catalogTypes.category">
          <template #label>
            <span class="body-2">Ветки нашего ассортимента</span>
          </template>
        </v-radio>
      </v-radio-group>

      <!-- Дерево поставщика -->
      <component :is="treeComponent">
        <template #loader>
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">Загрузка...</v-col>
            <v-col cols="6">
              <v-progress-linear
                color="indigo"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </template>
        <template #actions="{ resetChanges, saveChanges, hasChanges }">
          <v-divider class="mt-4"></v-divider>
          <v-sticky-actions>
            <div class="d-flex justify-end flex-column flex-md-row py-4">
              <v-btn
                :disabled="!hasChanges"
                class="mr-2"
                color="red lighten-1"
                @click="resetChanges"
                depressed
                outlined
                small
              >
                Отменить изменения
              </v-btn>
              <v-btn
                :disabled="!hasChanges"
                @click="saveChanges"
                class="mr-2"
                color="primary"
                depressed
                small
              >
                Сохранить
              </v-btn>
            </div>
          </v-sticky-actions>
        </template>
      </component>
    </template>

    <!-- Действия -->
    <template #actions>
      <v-void />
    </template>
  </StepSkeleton>
</template>

<script>
import StepSkeleton from '../StepSkeleton'
import DistributorTree from './DistributorTree'
import StructureTree from './StructureTree'
import CategoryTree from './CategoryTree'

const catalogTypes = {
  distributor: 'distributor',
  structure: 'structure',
  category: 'category',
}

export default {
  components: {
    StepSkeleton
  },
  data: () => ({
    selectedCatalogType: catalogTypes.distributor,
    initialized: false
  }),
  created() {
    this.catalogTypes = catalogTypes
  },
  computed: {
    treeComponent: function() {
      switch (this.selectedCatalogType) {
        case catalogTypes.distributor:
          return DistributorTree
        case catalogTypes.structure:
          return StructureTree
        case catalogTypes.category:
          return CategoryTree
      }
      return null
    }
  }
}
</script>

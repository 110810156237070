<template>
  <v-scroll-y-transition mode="out-in">
    <!-- Получение кандидатов на привязку -->
    <div
      v-if="getDistributorFeaturesBindingsFetching"
      key="bindings_fetching"
      class="flex-grow-1 bind-info"
    >
      <v-overlay color="white" :value="true" absolute>
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Выбор привязки вручную -->
    <div
      v-else-if="selectedFeature && stockFeaturesOpened"
      key="stock-feature-select"
      class="flex-grow-1"
    >
      <StockFeatureTree
        :selectedId="stockFeaturesSelectedId"
        :activeItems="stockFeaturesActiveItems"
        :openItems="stockFeaturesOpenItems"
        @close="stockFeaturesClose"
        @select-item="stockFeaturesSelect"
        @confirm="stockFeaturesConfirm"
      />
    </div>

    <!-- Информация о привязке -->
    <div
      v-else-if="selectedFeature"
      :key="selectedFeature.id"
      class="flex-grow-1 bind-info"
    >
      <!-- Привязанный элемент -->
      <div class="mb-5">
        <div class="subtitle-1">Привязанный элемент:</div>
        <v-row v-if="bindedFeature" align="center">
          <v-col cols="12" md="8">
            <v-chip
              class="d-block text-center"
              @click:close="stockFeaturesShowInStructure(bindedFeature)"
              close
              close-icon="mdi-magnify"
              outlined
            >
              {{ bindedFeature && bindedFeature.name }}
            </v-chip>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn @click="unbindButton()" color="error" outlined block small>
              Отвязать
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="font-italic py-4 empty-message">
            Нет привязки
          </v-col>
        </v-row>
      </div>

      <!-- Возможные элементы привязки -->
      <div class="mb-5">
        <div class="subtitle-1">Возможные элементы привязки:</div>
        <v-chip-group
          v-if="bindingItems.length"
          v-model="changedSettings.bindedId"
          class="mb-2"
          active-class="success success--text"
          column
        >
          <v-chip
            v-for="item in bindingItems"
            :key="item.id"
            :value="item.id"
            @click:close="stockFeaturesShowInStructure(item)"
            close
            close-icon="mdi-magnify"
            outlined
          >
            {{ item.name }}
          </v-chip>
        </v-chip-group>
        <div v-else class="mt-2 mb-3 font-italic">
          Нет подходящих элементов
        </div>
        <div class="d-flex justify-end flex-column flex-md-row">
          <v-btn
            @click="stockFeaturesOpen()"
            color="indigo lighten-2"
            dark
            small
            depressed
          >
            Выбрать вручную
          </v-btn>
        </div>
      </div>

      <div>
        <a
          @click.prevent="valuesBindingOpen"
          :class="[
            'dashed',
            { disabled: hasSettingsChanges || !settings.bindedId }
          ]"
        >
          Привязать значения
        </a>
        <BindFeatureValuesDialog
          :show="showFeatureValuesBind"
          :distributor-feature-id="selectedFeatureId"
          :stock-feature-id="settings.bindedId"
          @close="valuesBindingClose"
        />
      </div>

      <!-- Кнопки сохранения -->
      <v-divider class="my-4"></v-divider>
      <div class="d-flex justify-end flex-column flex-md-row">
        <v-btn
          @click="resetChanges()"
          :disabled="!hasSettingsChanges"
          class="mr-md-2"
          color="error"
          small
          depressed
        >
          Сбросить
        </v-btn>
        <v-btn
          @click="saveChanges()"
          :disabled="!hasSettingsChanges"
          color="primary"
          class="mt-1 mt-md-0"
          small
          depressed
        >
          Сохранить изменения
        </v-btn>
      </div>

      <!-- Оверлей при обновлении -->
      <v-overlay color="white" :value="getDistributorFeaturesUpdating" absolute>
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Лоадер -->
    <div v-else class="flex-grow-1 text-center py-12">
      <div class="mt-4 text-center" v-if="getStockFeaturesFetching">
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="title font-weight-light">
        Выберите параметр
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { scrollToTargetMixin } from '@/common/scroll/mixins'
import StockFeatureTree from './StockFeatureTree'
import BindFeatureValuesDialog from './BindFeatureValuesDialog'

export default {
  mixins: [scrollToTargetMixin],
  components: {
    StockFeatureTree,
    BindFeatureValuesDialog
  },
  props: {
    selectedFeature: Object
  },
  data: () => ({
    // Id выбранного бренда
    selectedFeatureId: null,
    // Настройки привязки выбранного бренда, устанавливаются при смене бренда
    defaultSettings: {
      bindedId: null
    },
    // Изменения настроек
    changedSettings: {
      bindedId: null
    },
    // Данные для ручного выбора элемента привязки
    stockFeaturesOpened: false,
    stockFeaturesSelectedId: null,
    showInStructureId: null,
    showFeatureValuesBind: false
  }),
  watch: {
    // Устанавливаем значения при смене выбранного параметра
    selectedFeature: function(distributorFeature) {
      // Сбрасываем значения
      if (
        !distributorFeature ||
        distributorFeature.id !== this.selectedFeatureId
      ) {
        this.stockFeaturesOpened = false
        this.stockFeaturesSelectedId = null
        this.showInStructureId = null
      }
      if (distributorFeature) {
        this.defaultSettings.bindedId = distributorFeature.bind
        if (distributorFeature.id !== this.selectedFeatureId) {
          this.changedSettings.bindedId = distributorFeature.bind
        }
      } else {
        this.defaultSettings.bindedId = null
        this.changedSettings.bindedId = null
      }
      this.selectedFeatureId = distributorFeature ? distributorFeature.id : null
    }
  },
  computed: {
    ...mapGetters('stock_features', [
      'getStockFeaturesFetching',
      'getStockFeatureById',
      'getStockFeaturesByIdList',
      'getStockFeatureAncestorsById'
    ]),
    ...mapGetters('distributor_features', [
      'getDistributorFeaturesUpdating',
      'getDistributorFeaturesBindingsFetching'
    ]),
    // Настройки с учетом изменений
    settings: function() {
      return {
        ...this.defaultSettings,
        ...this.changedSettings
      }
    },
    // Наличие изменений
    hasSettingsChanges: function() {
      return !_.isEqual(this.defaultSettings, this.changedSettings)
    },
    // Получаем объект привязанного параметра
    bindedFeature: function() {
      return this.settings.bindedId
        ? this.getStockFeatureById(this.settings.bindedId)
        : null
    },
    // Получаем список подходящих параметров для привязки
    bindingItems: function() {
      // Список подходящих параметров
      const idList = [...(this.selectedFeature.binding_items || [])]
      // Добавляем уже привязанный
      if (this.defaultSettings.bindedId) {
        idList.push(this.defaultSettings.bindedId)
      }
      // Добавляем параметр из измененных настроек
      if (this.changedSettings.bindedId) {
        idList.push(this.changedSettings.bindedId)
      }
      // Добавляем параметр выбранный руками
      if (this.stockFeaturesSelectedId) {
        idList.push(this.stockFeaturesSelectedId)
      }
      return this.getStockFeaturesByIdList(_.uniq(idList))
    },
    // Список id для автоматического раскрытия дерева
    stockFeaturesOpenItems: function() {
      // берем id элемента, который нужно отобразить в структуре или id выбранного элемента
      let itemId = this.showInStructureId || this.stockFeaturesSelectedId
      return itemId
        ? this.getStockFeatureAncestorsById(itemId).map(item => item.id)
        : []
    },
    // Список id подсвеченных элементов в структуре
    stockFeaturesActiveItems: function() {
      return this.showInStructureId ? [this.showInStructureId] : []
    }
  },
  methods: {
    ...mapActions('distributor_features', ['actionBindDistributorFeature']),
    // Открыть ручной выбор
    stockFeaturesOpen: function() {
      this.stockFeaturesOpened = true
    },
    // Закрыть ручной выбор
    stockFeaturesClose: function() {
      this.stockFeaturesOpened = false
      this.showInStructureId = null
    },
    // Событие ручного выбора
    stockFeaturesSelect: function(item) {
      this.stockFeaturesSelectedId =
        this.stockFeaturesSelectedId === item.id ? null : item.id
    },
    // Подтверждение ручного выбора
    stockFeaturesConfirm: function() {
      this.changedSettings.bindedId = this.stockFeaturesSelectedId
      this.stockFeaturesClose()
    },
    // Показать элемент в структуре
    stockFeaturesShowInStructure: function(item) {
      this.showInStructureId = item.id
      this.stockFeaturesOpen()
      this.tryScrollToTargetId(item.id)
    },
    // Кнопка очистки привязки
    unbindButton: function() {
      this.changedSettings.bindedId = null
    },
    // Сброс всех изменений
    resetChanges: function() {
      this.changedSettings = { ...this.defaultSettings }
    },
    // Сохранение изменений
    saveChanges: function() {
      this.actionBindDistributorFeature({
        id: this.selectedFeature.id,
        bindId: this.changedSettings.bindedId || null
      })
    },
    valuesBindingOpen: function() {
      if (!this.hasSettingsChanges && this.settings.bindedId) {
        this.showFeatureValuesBind = true
      }
    },
    valuesBindingClose: function() {
      this.showFeatureValuesBind = false
    }
  }
}
</script>

<style scoped lang="scss">
.bind-info {
  position: relative;

  .empty-message {
    margin-bottom: 2px;
  }
}
a.disabled {
  color: #757575;
  cursor: not-allowed;
}
</style>

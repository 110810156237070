import { scrollToTarget } from '../'

export default {
  methods: {
    scrollTargetId: function(id) {
      return `scroll_target_${id}`
    },
    tryScrollToTargetId: function(id) {
      scrollToTarget(`#${this.scrollTargetId(id)}`)
    }
  }
}

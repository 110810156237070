<template>
  <v-scroll-y-transition mode="out-in">
    <!-- Получение кандидатов на привязку -->
    <div
      v-if="getDistributorBrandsBindingsFetching"
      key="bindins_fetching"
      class="flex-grow-1 settings-bind-info"
    >
      <v-overlay color="white" :value="true" absolute>
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Выбор привязки вручную -->
    <div
      v-else-if="selectedBrand && stockBrandsOpened"
      key="stock-brands-select"
      class="flex-grow-1"
    >
      <!-- Поле фильтра -->
      <v-text-field
        v-model="stockBrandsSearch"
        label="Фильтрация брендов"
        flat
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        outlined
        dense
      ></v-text-field>

      <!-- Дерево выбора -->
      <v-treeview
        :items="brandsTree"
        :search="stockBrandsSearch"
        :open="stockBrandsOpenItems"
        :active="stockBrandsActiveItems"
        item-key="surrogate_id"
        class="py-3"
        hoverable
        open-on-click
        dense
      >
        <template #append="{ item }">
          <v-btn
            v-if="item.is_brand"
            @click="stockBrandsSelect(item)"
            :color="stockBrandsSelectButtonColor(item)"
            :id="scrollTargetId(item.id)"
            small
            depressed
          >
            {{ stockBrandsSelectButtonText(item) }}
          </v-btn>
        </template>
      </v-treeview>

      <v-sticky-actions>
        <div class="d-flex justify-end flex-column flex-md-row py-4">
          <v-btn
            @click="stockBrandsClose()"
            class="mr-2"
            color="grey darken-1"
            outlined
            small
            depressed
          >
            Назад
          </v-btn>
          <v-btn
            :disabled="!this.stockBrandsSelectedId"
            @click="stockBrandsConfirm()"
            color="success"
            small
            depressed
          >
            Продолжить
          </v-btn>
        </div>
      </v-sticky-actions>
    </div>

    <!-- Информация о привязке -->
    <div
      v-else-if="selectedBrand"
      :key="selectedBrand.id"
      class="flex-grow-1 settings-bind-info"
    >
      <div class="mb-5">
        <!-- Привязанный элемент -->
        <div class="subtitle-1">Привязанный элемент:</div>
        <v-row v-if="bindedBrand" align="center">
          <v-col cols="12" md="8">
            <v-chip
              @click:close="stockBrandsShowInStructure(bindedBrand)"
              class="d-block text-center"
              close
              close-icon="mdi-magnify"
              outlined
            >
              {{ bindedBrand && bindedBrand.name }}
            </v-chip>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn @click="unbindButton()" color="error" outlined block small>
              Отвязать
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="font-italic py-4">
            Нет привязки
          </v-col>
        </v-row>
      </div>

      <!-- Возможные элементы привязки -->
      <div class="subtitle-1">Возможные элементы привязки ЦСМ:</div>
      <v-chip-group
        v-if="bindingItems.length"
        v-model="changedSettings.bindedId"
        class="mb-2"
        active-class="success success--text"
        column
      >
        <v-chip
          v-for="item in bindingItems"
          :key="item.id"
          :value="item.id"
          @click:close="stockBrandsShowInStructure(item)"
          close
          close-icon="mdi-magnify"
          outlined
        >
          {{ item.name }}
        </v-chip>
      </v-chip-group>
      <div v-else class="mt-2 mb-3 font-italic">
        Нет подходящих элементов
      </div>
      <div class="d-flex justify-end flex-column flex-md-row">
        <v-btn
          v-if="getDistributorAttachedBrands.length"
          @click="stockBrandsOpen(true)"
          color="indigo lighten-2"
          class="mr-md-2"
          dark
          small
          depressed
        >
          Бренды поставщика
        </v-btn>
        <v-btn
          @click="stockBrandsOpen()"
          color="indigo lighten-2"
          class="mt-1 mt-md-0"
          dark
          small
          depressed
        >
          Все бренды
        </v-btn>
      </div>

      <!-- Кнопки сохранения -->
      <v-divider class="my-4"></v-divider>
      <div class="d-flex justify-end flex-column flex-md-row">
        <v-btn
          @click="resetChanges()"
          :disabled="!hasSettingsChanges"
          class="mr-md-2"
          color="error"
          small
          depressed
        >
          Сбросить
        </v-btn>
        <v-btn
          @click="saveChanges()"
          :disabled="!hasSettingsChanges"
          class="mt-1 mt-md-0"
          color="primary"
          small
          depressed
        >
          Сохранить изменения
        </v-btn>
      </div>

      <!-- Оверлей при обновлении -->
      <v-overlay color="white" :value="getDistributorBrandsUpdating" absolute>
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Лоадер -->
    <div v-else class="flex-grow-1 text-center py-12">
      <div class="mt-4 text-center" v-if="getStockBrandsFetching">
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="title font-weight-light">
        Выберите бренд
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { scrollToTargetMixin } from '@/common/scroll/mixins'

export default {
  mixins: [scrollToTargetMixin],
  props: {
    selectedBrand: Object
  },
  data: () => ({
    // Id выбранного бренда
    selectedBrandId: null,
    // Настройки привязки выбранного бренда, устанавливаются при смене бренда
    defaultSettings: {
      bindedId: null
    },
    // Изменения настроек
    changedSettings: {
      bindedId: null
    },
    // Данные для ручного выбора элемента привязки
    stockBrandsOpened: false,
    stockBrandsSelectedId: null,
    stockBrandsSearch: '',
    stockBrandsOnlyAttached: false,
    // id элемента для показа в дереве
    showInStructureId: null
  }),
  watch: {
    // Устанавливаем значения при смене бренда
    selectedBrand: function(distributorBrand) {
      // Сбрасываем значения
      if (distributorBrand && distributorBrand.id !== this.selectedBrandId) {
        this.stockBrandsOpened = false
        this.stockBrandsSelectedId = null
        this.showInStructureId = null
      }
      if (distributorBrand) {
        this.defaultSettings.bindedId = distributorBrand.bind
        if (distributorBrand.id !== this.selectedBrandId) {
          this.changedSettings.bindedId = distributorBrand.bind
        }
      } else {
        this.defaultSettings.bindedId = null
        this.changedSettings.bindedId = null
      }
      this.selectedBrandId = distributorBrand ? distributorBrand.id : null
    }
  },
  computed: {
    ...mapGetters('stock_brands', [
      'getStockBrandsTree',
      'getStockBrandsFetching',
      'getStockBrandById',
      'getStockBrandsByIdList',
      'getStockBrandsTreeByIdList'
    ]),
    ...mapGetters('distributor_brands', [
      'getDistributorBrandsUpdating',
      'getDistributorBrandsBindingsFetching'
    ]),
    ...mapGetters('distributor', ['getDistributorAttachedBrands']),
    // Настройки с учетом изменений
    settings: function() {
      return {
        ...this.defaultSettings,
        ...this.changedSettings
      }
    },
    // Наличие изменений
    hasSettingsChanges: function() {
      return !_.isEqual(this.defaultSettings, this.changedSettings)
    },
    // Дерево брендов
    brandsTree: function() {
      return this.stockBrandsOnlyAttached
        ? this.getStockBrandsTreeByIdList(this.getDistributorAttachedBrands)
        : this.getStockBrandsTree
    },
    // Получаем объект привязанного бренда
    bindedBrand: function() {
      if (this.settings.bindedId) {
        return this.getStockBrandById(this.settings.bindedId)
      }
      return null
    },
    // Получаем список подходящих брендов для привязки
    bindingItems: function() {
      // Список подходящих брендов, пришедщий с сервера
      const idList = [...(this.selectedBrand.binding_items || [])]
      // Добавляем уже привязанный бренд
      if (this.defaultSettings.bindedId) {
        idList.push(this.defaultSettings.bindedId)
      }
      // Добавляем бренд из измененных настроек
      if (this.changedSettings.bindedId) {
        idList.push(this.changedSettings.bindedId)
      }
      // Добавляем бренда выбранный руками
      if (this.stockBrandsSelectedId) {
        idList.push(this.stockBrandsSelectedId)
      }
      // Удаляем дубли
      return this.getStockBrandsByIdList(
        idList.filter((value, index, self) => self.indexOf(value) === index)
      )
    },
    // Список id для автоматическое раскрытия дерева
    stockBrandsOpenItems: function() {
      // берем id элемента, который нужно отобразить в структуре или id выбранного элемента
      let itemId = this.showInStructureId || this.stockBrandsSelectedId
      if (itemId) {
        const brand = this.getStockBrandById(itemId)
        if (brand) {
          return [brand.surrogate_id, brand.surrogate_category_id]
        }
      }
      return []
    },
    // Список id подсвеченных элементов в структуре
    stockBrandsActiveItems: function() {
      const brand = this.showInStructureId
        ? this.getStockBrandById(this.showInStructureId)
        : null
      return brand ? [brand.surrogate_id] : []
    }
  },
  methods: {
    ...mapActions('distributor_brands', ['actionBindDistributorBrand']),
    // Открыть ручной выбор
    stockBrandsOpen: function(onlyAttached = false) {
      this.stockBrandsOnlyAttached = onlyAttached
      this.stockBrandsOpened = true
    },
    // Закрыть ручной выбор
    stockBrandsClose: function() {
      this.stockBrandsOpened = false
      this.stockBrandsSearch = ''
      this.showInStructureId = null
    },
    // Событие ручного выбора
    stockBrandsSelect: function(item) {
      this.stockBrandsSelectedId = item.id
    },
    // Подтверждение ручного выбора
    stockBrandsConfirm: function() {
      this.changedSettings.bindedId = this.stockBrandsSelectedId
      this.stockBrandsClose()
    },
    // Показать элемент в структуре
    stockBrandsShowInStructure: function(item) {
      this.showInStructureId = item.id
      this.stockBrandsOpen()
      this.tryScrollToTargetId(item.id)
    },
    // Цвет кнопки
    stockBrandsSelectButtonColor: function(item) {
      return this.stockBrandsSelectedId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки
    stockBrandsSelectButtonText: function(item) {
      return this.stockBrandsSelectedId === item.id ? 'Выбрано' : 'Выбрать'
    },
    // Кнопка очистки привязки
    unbindButton: function() {
      this.changedSettings.bindedId = null
    },
    // Сброс всех изменений
    resetChanges: function() {
      this.changedSettings = { ...this.defaultSettings }
    },
    // Сохранение изменений
    saveChanges: function() {
      this.actionBindDistributorBrand({
        brandId: this.selectedBrand.id,
        bindId: this.changedSettings.bindedId || null
      })
    }
  }
}
</script>

<style scoped lang="scss">
.settings-bind-info {
  position: relative;
}
</style>

<template>
  <div>
    <!-- Спинер -->
    <div
      class="my-4 text-center"
      v-if="stockCategoriesSelectedFetching || getStockCategoriesFetching"
    >
      <slot name="loader"></slot>
    </div>
    <div v-else>
      <v-overlay
        color="white"
        :value="stockCategoriesSelectedUpdating"
        absolute
      >
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <div class="subtitle-1 font-weight-medium">Выберите нужные разделы:</div>

      <!-- Чекбокс вкл/выкл всех -->
      <v-checkbox
        label="Все элементы"
        class="mt-1"
        @change="switchAll"
        dense
        hide-details
      ></v-checkbox>
      <v-treeview
        :items="getStockRootCategories"
        :active="activeItems"
        selection-type="independent"
        class="pb-3 pt-2"
        multiple-active
        hoverable
        open-on-click
        dense
      >
        <template #prepend="{ item }">
          <SelectTreeChildsDialog @select="checkboxHandler(item, $event)">
            <template v-slot:activator="{on, attrs}">
              <v-checkbox
                :input-value="item.selected"
                class="ma-0 pa-0 tree_checkbox"
                hide-details
                dense
                v-on="on"
                v-bind="attrs"
              ></v-checkbox>
            </template>
          </SelectTreeChildsDialog>
        </template>
        <template #label="{ item }">
          <span :class="!item.enabled && 'grey--text darken-3'">
            {{ item.name }}
          </span>
        </template>
      </v-treeview>
      <slot
        name="actions"
        :resetChanges="resetChanges"
        :saveChanges="saveChanges"
        :hasChanges="hasChanges"
      ></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions, mapState } from 'vuex'
import SelectTreeChildsDialog from "@/components/SelectTreeChildsDialog";

const itemIsActive = item => {
  if (item.selected) {
    return true
  } else if (item.children) {
    return item.children.some(itemIsActive)
  }
  return false
}

export default {
  components: {
    SelectTreeChildsDialog
  },
  mounted() {
    this.actionFetchStockCategories()
    if (this.activeDistributor) {
      this.actionFetchSelectedStockCategories(this.activeDistributor)
    }
  },
  watch: {
    // Загрузка категорий при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.actionFetchSelectedStockCategories(distributorId)
      }
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapState('stock_categories', {
      stockCategoriesSelectedFetching: state =>
        state.stock_categories_selected_fetching,
      stockCategoriesSelectedUpdating: state =>
        state.stock_categories_selected_updating
    }),
    ...mapGetters('stock_categories', [
      'getStockCategoriesSelected',
      'getStockCategoriesTree',
      'getStockCategoryChildsIdRecursive',
      'getStockRootCategories',
      'getStockCategoriesFetching'
    ]),
    activeItems: function() {
      return this.getStockCategoriesTree.length
        ? this.getStockCategoriesTree
            .filter(item => itemIsActive(item))
            .map(item => item.id)
        : []
    },
    selectedItems: function() {
      return (
        this.getStockCategoriesSelected.changed ||
        this.getStockCategoriesSelected.default
      )
    },
    hasChanges: function() {
      return this.getStockCategoriesSelected.changed
        ? !_.isEqual(
            _.sortBy(this.getStockCategoriesSelected.changed),
            _.sortBy(this.getStockCategoriesSelected.default)
          )
        : false
    }
  },
  methods: {
    ...mapActions('stock_categories', [
      'actionFetchStockCategories',
      'actionFetchSelectedStockCategories',
      'actionUpdateSelectedStockCategories',
      'actionSetStockCategoriesSelected'
    ]),
    checkboxHandler: function(item, selectChilds) {
      let checked = this.selectedItems.includes(item.id)
      let selectedItems = []
      if (selectChilds) {
        selectedItems = checked
          ? _.difference(this.selectedItems, [
              item.id,
              ...this.getStockCategoryChildsIdRecursive(item.id)
            ])
          : _.concat(this.selectedItems, [
              item.id,
              ...this.getStockCategoryChildsIdRecursive(item.id)
            ])
      } else {
        selectedItems = checked
          ? _.difference(this.selectedItems, [item.id])
          : _.concat(this.selectedItems, [item.id])
      }
      this.actionSetStockCategoriesSelected({ changed: selectedItems })
    },
    // Кнопка отмена изменений
    resetChanges: function() {
      this.actionSetStockCategoriesSelected({ changed: null })
    },
    // Кнопка сохранить изменения
    saveChanges: function() {
      this.actionUpdateSelectedStockCategories({
        distributorId: this.activeDistributor,
        toggleOn: _.difference(
          this.getStockCategoriesSelected.changed,
          this.getStockCategoriesSelected.default
        ),
        toggleOff: _.difference(
          this.getStockCategoriesSelected.default,
          this.getStockCategoriesSelected.changed
        )
      }).then(() => {
        this.actionSetStockCategoriesSelected({
          default: this.getStockCategoriesSelected.changed,
          changed: null
        })
      })
    },
    // Вкл/Выкл все записи
    switchAll: function(value) {
      this.actionSetStockCategoriesSelected({
        changed: value ? this.getStockCategoriesTree.map(item => item.id) : null
      })
    }
  }
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="thin-table"
    :items-per-page="-1"
    hide-default-footer
  >
    <template #item="{ item }">
      <tr :class="{ highlighted: isHighlighted(item) }">
        <td class="name text-start">
          {{ item.name }}
        </td>
        <td>
          <v-btn
            :color="buttonColor(item)"
            @click="$emit('select-item', item)"
            :id="scrollTargetId(`${item.id}`)"
            small
            depressed
          >
            {{ buttonText(item) }}
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { scrollToTargetMixin } from '@/common/scroll/mixins'

export default {
  mixins: [scrollToTargetMixin],
  props: {
    items: Array,
    selectedItemId: Number,
    highlightedItems: {
      type: Array,
      default: () => []
    },
    search: String
  },
  created() {
    this.headers = [
      {
        text: 'Название',
        value: 'name',
        width: '100%'
      },
      {
        text: '',
        value: 'select',
        sortable: false
      }
    ]
  },
  methods: {
    // Цвет кнопки выбора
    buttonColor: function(item) {
      return this.selectedItemId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки выбора
    buttonText: function(item) {
      return this.selectedItemId === item.id ? 'Выбрано' : 'Выбрать'
    },
    isHighlighted: function(item) {
      return this.highlightedItems.includes(item.id)
    }
  }
}
</script>

<style scoped lang="scss">
tr.highlighted {
  background-color: #b3d4fc;

  .name {
    color: #1976d2;
  }
}
</style>

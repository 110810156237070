<template>
  <div>
    <div class="subtitle-1">Выберите параметры товара для ручного поиска в 1С:</div>

    <div v-if="item.article">
      <v-checkbox @change="toggleSearchKeyword($event, item.article)">
	      <template #label>
		      <span class="pr-2 subtitle-2">Артикул:&nbsp;</span>
          <span class="body-2">{{ item.article || '-' }}</span>
	      </template>
      </v-checkbox>
    </div>
    <div v-if="brandName">
	    <v-checkbox class="my-n3" @change="toggleSearchKeyword($event, brandName)">
		    <template #label>
			    <span class="pr-2 subtitle-2">Бренд:&nbsp;</span>
		      <span class="body-2">{{ brandName || '-' }}</span>
		    </template>
	    </v-checkbox>
    </div>
    <div>
	    <v-checkbox class="my-n3" @change="toggleSearchKeyword($event, item.name)">
		    <template #label>
		      <span class="pr-2 subtitle-2">Название:&nbsp;</span>
		      <span class="body-2">{{ item.name }}</span>
		    </template>
	    </v-checkbox>
    </div>
    <v-form ref="form" @submit.prevent="search">
      <v-container pa-0 fluid>
        <v-row>
          <v-col class="flex-grow-1">
            <v-text-field
              v-model="input"
              label="Строка поиска"
              hide-details
              clearable
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col class="flex-grow-0">
            <v-btn
              type="submit"
              :disabled="!trimedInput.length"
              color="primary"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- Результаты -->
    <div v-if="getStockProductsSearchFetching" class="text-center py-3">
      <v-progress-circular
        :size="50"
        color="indigo lighten-4"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="subtitle-1">Результаты поиска:</div>
      <div v-if="resultItems.length">
        <StockProductTable :items="resultItems" :itemsPerPage="itemsPerPage">
          <template #action="{ item }">
            <v-btn
              @click="selectItem(item)"
              :color="buttonColor(item)"
              small
              depressed
            >
              {{ buttonText(item) }}
            </v-btn>
          </template>
        </StockProductTable>
      </div>
      <div v-else class="text-center">
        <div class="pt-2 font-italic font-weight-light">Нет результатов</div>
      </div>
    </div>

    <!-- Кнопки -->
    <v-divider class="mt-4"></v-divider>
    <v-sticky-actions>
      <div class="d-flex justify-end flex-column flex-md-row py-4">
        <v-btn
          @click="$emit('close')"
          class="mr-2"
          color="grey darken-1"
          outlined
          small
          depressed
        >
          Назад
        </v-btn>
        <v-btn
          @click="$emit('confirm')"
          :disabled="!selectedId"
          color="success"
          small
          depressed
        >
          Продолжить
        </v-btn>
      </div>
    </v-sticky-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { scrollToTarget } from '@/common/scroll'
import StockProductTable from './StockProductTable'

const itemsPerPage = 10

export default {
  props: {
    item: Object,
    selectedId: Number
  },
  components: {
    StockProductTable
  },
  created() {
    this.itemsPerPage = itemsPerPage
  },
  mounted() {
    scrollToTarget('#top-content-anchor')
  },
  data: () => ({
    input: ''
  }),
  computed: {
    ...mapGetters('distributor_brands', ['getDistributorBrandById']),
    ...mapGetters('stock_products', [
      'getStockProductsSearch',
      'getStockProductsSearchFetching'
    ]),
    trimedInput: function() {
      return this.input ? this.input.trim() : ''
    },
    resultItems: function() {
      return this.getStockProductsSearch
    },
    brandName: function() {
      const brand = this.getDistributorBrandById(this.item.brand)
      return brand ? brand.name : ''
    }
  },
  methods: {
    ...mapActions('stock_products', ['actionSearchStockProducts']),
    selectItem: function(item) {
      this.$emit('select-item', item)
    },

	  // Добавление подстроки к запросу
	  toggleSearchKeyword: function(insert_mode, text) {
		  if (insert_mode) {
			  if (this.input.search(text) === -1) {
				  this.input = `${this.trimedInput} ${text}`.trim()
			  }
		  } else {
        this.input = this.input.split(text).join('')
		  }
	  },

    // Запрос поиска
    search: function() {
      if (this.trimedInput.length) {
        this.selectItem(null)
        this.actionSearchStockProducts({
          q: this.trimedInput,
          items_per_page: itemsPerPage,
          distributor: this.$store.getters["app_aggregator/activeDistributor"]
        })
      }
    },
    // Цвет кнопки
    buttonColor: function(item) {
      return this.selectedId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки
    buttonText: function(item) {
      return this.selectedId === item.id ? 'Выбрано' : 'Выбрать'
    }
  }
}
</script>

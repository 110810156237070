<template>
  <v-dialog max-width="700" scrollable v-model="show">
    <template #activator="{on, attrs}">
      <v-icon v-on="on" v-bind="attrs">mdi-pencil</v-icon>
    </template>
    <template #default>
      <v-card>
        <v-card-title>
          <span v-if="categoryType == CATEGORY_TYPES.TREE">Выбор ветви ассортимента</span>
          <span v-else-if="categoryType == CATEGORY_TYPES.CATEGORY">Выбор папки номенклатуры</span>
        </v-card-title>
        <v-card-text>
          <v-treeview
              class="item-change-tree"
              dense
              ref="tree"
              :items="treeRoot"
              :active="activeItems"
              multiple-active
              open-on-click
              hoverable
          >
            <template #append="{item}">
              <v-btn
                  v-if="item.selected && ((categoryType == CATEGORY_TYPES.TREE && !item.is_group) || categoryType == CATEGORY_TYPES.CATEGORY)"
                  @click="select(item.id)"
                  small
                  color="primary"
              >Выбрать
              </v-btn>
            </template>
          </v-treeview>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="grey" outlined @click="show = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

/** Типы категорий. Ветвь ассортимента и папка номенклатуры соответственно */
export const CATEGORY_TYPES = {
  TREE: 'TREE',
  CATEGORY: 'CATEGORY'
}

const itemIsActive = item => {
  if (item.selected && ((item.hasOwnProperty('is_group') && !item.is_group) || !item.hasOwnProperty('is_group'))) {
    return true
  } else if (item.children) {
    return item.children.some(itemIsActive)
  }

  return false
}

export default {
  name: "VariantChangeItemDialog",
  props: {
    categoryType: String,
  },
  data: () => ({
    show: false,
    CATEGORY_TYPES,
  }),
  methods: {
    itemIsActive,
    select(id) {
      this.$emit('choiced', id)
      this.show = false
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('stock_tree', ['getStockRootTree', 'getStockTreeSelected', 'getStockTreeById']),
    ...mapGetters('stock_categories', ['getStockRootCategories', 'getStockCategoriesSelected']),

    /**
     * Дерево категорий, в зависимости от указанного типа для компонента
     */
    treeRoot() {
      switch(this.categoryType) {
        case CATEGORY_TYPES.TREE:
          return this.getStockRootTree

        case CATEGORY_TYPES.CATEGORY:
          return this.getStockRootCategories

        default:
          throw `Неизвестный тип категории ${this.categoryType} при получении корневых категорий`
      }
    },

    /**
     * Ноды категории, которые выбранны для работы в этом поставщике
     */
    selectedNodes() {
      switch(this.categoryType) {
        case CATEGORY_TYPES.TREE:
          return this.getStockTreeSelected.default

        case CATEGORY_TYPES.CATEGORY:
          return this.getStockCategoriesSelected.default

        default:
          throw `Неизвестный тип категории ${this.categoryType} при получении активных узлов`
      }
    },

    /**
     * Индентификаторы всех активных нод дерева (для v-treeview)
     */
    activeItems() {
      let items = Array.from(this.selectedNodes)

      this.treeRoot.map(e => {
        if (itemIsActive(e)) {
         items.push(e.id)
        } else {
          items = items.filter(i => i != e.id)
        }
      })

      return items
    },
  }
}
</script>

<style lang="scss">
.item-change-tree .v-treeview-node__root {
  display: none;
}

.item-change-tree .v-treeview-node__root.v-treeview-node--active {
  display: flex;
}

.item-change-tree .v-treeview-node__label {
  color: black;
}

.item-change-tree .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0 !important;
}
</style>
<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :hide-default-footer="hideDefaultFooter"
    :disable-sort="disableSort"
    class="thin-table"
    dense
  >
    <template #item.enabled="{ item }">
      <v-switch
        :input-value="item.enabled"
        :disabled="item.loading || item.bind != null"
        :loading="item.loading"
        @change="switchItem(item)"
        class="my-3"
        hide-details
      ></v-switch>
    </template>
    <template #item.select="{ item }">
      <v-btn
        :color="buttonColor(item)"
        @click="$emit('select-item', item)"
        small
        depressed
      >
        {{ buttonText(item) }}
      </v-btn>
    </template>
    <template #item.status="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon :color="linkColor(item)" v-on="on">
            mdi-link-box-variant
          </v-icon>
        </template>
        <span>{{ linkTooltipText(item) }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    items: Array,
    loading: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    selectedItem: Object
  },
  created() {
    this.headers = [
      {
        text: 'Название поставщика',
        value: 'name',
        width: '100%'
      },
      {
        text: 'Активность',
        value: 'enabled',
        sortable: false
      },
      {
        text: 'Статус',
        value: 'status',
        sortable: false
      },
      {
        text: '',
        value: 'select',
        sortable: false
      }
    ]
  },
  computed: {
    selectedItemId: function() {
      return this.selectedItem && this.selectedItem.id
        ? this.selectedItem.id
        : null
    }
  },
  methods: {
    ...mapActions('distributor_brands', ['actionSwitchDistributorBrand']),
    // Цвет иконки привязки
    linkColor: function(item) {
      return item.bind !== null ? 'success' : 'grey darken-3'
    },
    // Текст тултипа иконки привязки
    linkTooltipText: function(item) {
      return item.bind !== null ? 'Связано' : 'Не связано'
    },
    // Цвет кнопки выбора
    buttonColor: function(item) {
      return this.selectedItemId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки выбора
    buttonText: function(item) {
      return this.selectedItemId === item.id ? 'Выбрано' : 'Выбрать'
    },
    // Вкл/выкл бренд
    switchItem: function(item) {
      this.actionSwitchDistributorBrand({
        brandId: item.id,
        enabled: !item.enabled
      })
    }
  }
}
</script>

<template>
  <v-dialog :value="show" width="700" persistent scrollable>
    <v-card>
      <v-card-title>Привязка значений параметров</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="getDistributorProducts"
          :items-per-page="itemsPerPage"
          class="thin-table"
          disable-sort
          hide-default-footer
          dense
        >
          <template #item.enabled="{ item }">
            <v-switch
              :input-value="item.enabled"
              :disabled="item.bind != null"
              @change="switchItem(item)"
            ></v-switch>
          </template>
          <template #item.photos="{ item }">
            <v-popup-image class="my-2" :src-original="getPhoto(item)" />
          </template>
          <template #item.info="{ item }">
            <div>
              <b>Артикул:</b>
              {{ item.article || 'нет' }}
            </div>
            <div>
              <b>Бренд:</b>
              {{ getBrandName(item) }}
            </div>
            <div>
              <b>Название:</b>
              {{ item.name }}
            </div>
          </template>
          <template #item.status="{ item }">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon :color="linkColor(item)" v-on="on">
                  mdi-link-box-variant
                </v-icon>
              </template>
              <span>{{ linkTooltipText(item) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <div v-if="countPages" class="text-center pt-4">
          <v-pagination
            v-model="page"
            :length="countPages"
            total-visible="10"
          ></v-pagination>
        </div>
        <v-overlay
          color="white"
          :value="
            getDistributorProductsFetching || getDistributorProductsUpdating
          "
          absolute
        >
          <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" color="grey darken-1" outlined>
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    categoryId: {
      type: Number,
      default: null
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  created() {
    this.headers = [
      {
        text: '',
        value: 'enabled',
        align: 'center'
      },
      {
        text: 'Фото',
        value: 'photos',
        align: 'center',
        width: '80'
      },
      {
        text: 'Информация',
        value: 'info',
        width: '100%'
      },
      {
        text: '',
        value: 'status',
        align: 'center'
      }
    ]
  },
  data: () => ({
    page: 1,
    isClearing: false // игнорирование изменений page
  }),
  watch: {
    categoryId: function() {
      if (this.categoryId) {
        this.fetchProducts()
      } else {
        this.actionClearDistributorProducts()
        this.isClearing = true
        this.page = 1
      }
    },
    page: function() {
      if (this.isClearing) {
        this.isClearing = false
        return
      }
      this.fetchProducts()
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_brands', ['getDistributorBrandById']),
    ...mapGetters('distributor_products', [
      'getDistributorProducts',
      'getDistributorProductsFetching',
      'getDistributorProductsUpdating',
      'getDistributorProductsCount'
    ]),
    show: function() {
      return this.categoryId !== null
    },
    countPages: function() {
      return Math.ceil(this.getDistributorProductsCount / this.itemsPerPage)
    }
  },
  methods: {
    ...mapActions('distributor_products', [
      'actionFetchDistributorProducts',
      'actionClearDistributorProducts',
      'actionSwitchDistributorProduct'
    ]),
    getPhoto: function(item) {
      return item.images && item.images.length ? item.images[0].url : ''
    },
    getBrandName: function(item) {
      const brand = this.getDistributorBrandById(item.brand)
      return brand ? brand.name : 'нет'
    },
    // Цвет иконки привязки
    linkColor: function(item) {
      return item.bind !== null ? 'success' : 'grey darken-3'
    },
    // Текст тултипа иконки привязки
    linkTooltipText: function(item) {
      return item.bind !== null ? 'Связано' : 'Не связано'
    },
    fetchProducts: function() {
      this.isClearing = false
      this.actionFetchDistributorProducts({
        category: this.categoryId,
        distributor: this.activeDistributor,
        items_per_page: this.itemsPerPage,
        page: this.page
      })
    },
    switchItem: function(item) {
      this.actionSwitchDistributorProduct({
        id: item.id,
        enabled: !item.enabled
      })
    }
  }
}
</script>

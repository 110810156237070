<template>
  <v-dialog persistent v-model="show" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <template>
      <v-card>
        <v-card-title>Отметить вложенные уровни?</v-card-title>
        <v-card-text>
          <div class="d-flex justify-center py-2">
            <v-btn class="orange--text mr-3" outlined @click="answer(true)">Да</v-btn>
            <v-btn class="green--text mr-3" outlined @click="answer(false)">Нет</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectTreeChildsDialog",
  data: () => ({
    show: false
  }),
  methods: {
    answer(yes_or_no) {
      this.$emit('select', yes_or_no)
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>
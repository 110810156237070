<template>
  <div>
    <div v-if="showTree">
      <v-checkbox
          v-model="filterNotConfigured"
          label="Только не настроенные элементы"
          hide-details
      ></v-checkbox>
      <!-- Дерево категорий -->
      <v-treeview
          :items="items"
          :active="activeItems"
          class="py-3 distributor-catalogs"
          id="distributor_bind_catalog_tree"
          hoverable
          open-on-click
          dense
          multiple-active
      >
        <template #label="{ item }">
          <span :style="{'color': item.selected ? 'black': 'gray'}">
            {{ item.name }}
          </span>
          <span class="font-weight-thin">
            {{ item.products_count ? `(${item.products_count})` : '' }}
          </span>
        </template>
        <template #append="{ item }">
          <DistributorCatalogsButtons
              :item="item"
              v-if="item.selected"
              :selectedId="selectedItemId"
              :selectItem="selectItem"
              @show-products="openProductsDialog"
          />
        </template>
      </v-treeview>
      <!-- Модальное окно со списком товаров -->
      <DistributorProductsDialog
          :category-id="showProductsForCategoryId"
          :items-per-page="7"
          @close="closeProductsDialog"
      />
    </div>
    <div v-else class="title font-weight-light text-center py-12">
      <!-- Спинер -->
      <div v-if="getDistributorCategoriesFetching">
        <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
        ></v-progress-circular>
      </div>
      <!-- Сообщение если нет категорий -->
      <div v-else>
        Нет активных каталогов
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import * as catalogMergeMethods from '@/common/catalogMergeMethods'
import DistributorCatalogsButtons from './DistributorCatalogsButtons'
import DistributorProductsDialog from './DistributorProductsDialog'
import {buildTree} from "@/store/common";


/**
 * Является ли категория выбранной у дистрибьютора в настройках фильтрации
 * @param item
 * @returns {boolean|*}
 */
const itemIsActive = item => {
  if (item.selected) {
    return true
  } else if (item.children) {
    return item.children.some(itemIsActive)
  }
  return false
}

export default {
  components: {
    DistributorProductsDialog,
    DistributorCatalogsButtons
  },
  props: {
    onSelect: Function
  },
  data: () => ({
    selectedItemId: null,
    filterNotConfigured: false,
    showProductsForCategoryId: null,
  }),
  methods: {
    itemIsActive,
    ...mapActions('distributor_categories', ['actionFetchCategorySuggest']),
    setSelectedItem: function (itemId, item) {
      this.selectedItemId = itemId
      this.onSelect(item)
    },
    // Клик по кнопке выбора
    selectItem: function (item) {
      if (this.selectedItemId === item.id) {
        this.setSelectedItem(null, null)
      } else {
        this.actionFetchCategorySuggest(item.id).then(() => {
          this.setSelectedItem(item.id, item)
        })
      }
    },
    openProductsDialog: function (id) {
      this.showProductsForCategoryId = id
    },
    closeProductsDialog: function () {
      this.showProductsForCategoryId = null
    }
  },
  computed: {
    ...mapGetters('distributor_categories', [
      'getDistributorRootCategories',
      'getDistributorCategoriesFetching',
      'getFilteredDistributorRootCategories',
      'getDistributorCategoriesTree',
      'getDistributorCategories'
    ]),
    items: function () {
      return (
        this.filterNotConfigured
          ? this.getFilteredDistributorRootCategories(item => {
            return (
                item.merge_method === catalogMergeMethods.ignore
                || this.selectedItemId === item.id
            ) && itemIsActive(item)
          })
          : this.getDistributorRootCategories.filter(itemIsActive)
      )
    },
    activeItems: function() {
      return this.getDistributorCategoriesTree.length
        ? this.getDistributorCategoriesTree
            .filter(item => itemIsActive(item))
            .map(item => item.id)
        : []
    },
    showTree: function () {
      return this.getDistributorRootCategories.length > 0
    }
  }
}
</script>

<style lang="scss">
.distributor-catalogs .v-treeview-node__root {
  display: none;
}

.distributor-catalogs .v-treeview-node__root.v-treeview-node--active {
  display: flex;
}

.distributor-catalogs .v-treeview-node__label {
  color: black;
}

.distributor-catalogs .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0 !important;
}
</style>
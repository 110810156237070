<template>
  <v-scroll-y-transition mode="out-in">
    <!-- Выбор привязки вручную -->
    <div
      v-if="selectedItem && stockOpened"
      key="stock-items-select"
      class="flex-grow-1"
    >
      <!-- Поле фильтра -->
      <v-text-field
        v-model="stockSearch"
        label="Фильтрация юнитов"
        flat
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        outlined
        dense
      ></v-text-field>

      <!-- Таблица выбора -->
      <StockUnitTable
        :items="getStockUnits"
        :highlightedItems="stockHighlightedItems"
        :selectedItemId="stockSelectedItemId"
        :search="stockSearch"
        @select-item="stockItemSelect"
      />

      <v-sticky-actions>
        <div class="d-flex justify-end flex-column flex-md-row py-4">
          <v-btn
            @click="stockClose"
            class="mr-2"
            color="grey darken-1"
            outlined
            small
            depressed
          >
            Назад
          </v-btn>
          <v-btn
            :disabled="!this.stockSelectedItemId"
            @click="stockConfirm()"
            color="success"
            small
            depressed
          >
            Продолжить
          </v-btn>
        </div>
      </v-sticky-actions>
    </div>

    <!-- Информация о привязке -->
    <div
      v-else-if="selectedItem"
      :key="selectedItem.id"
      class="flex-grow-1 settings-bind-info"
    >
      <div class="mb-5">
        <!-- Привязанный элемент -->
        <div class="subtitle-1">Привязанный элемент:</div>
        <v-row v-if="bindedItem" align="center">
          <v-col cols="12" md="8">
            <v-chip
              @click:close="showInStockTable(bindedItem)"
              class="d-block text-center"
              close-icon="mdi-magnify"
              close
              outlined
            >
              {{ bindedItem && bindedItem.name }}
            </v-chip>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn @click="unbindButton()" color="error" outlined block small>
              Отвязать
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="font-italic py-4">
            Нет привязки
          </v-col>
        </v-row>
      </div>

      <div class="d-flex justify-end flex-column flex-md-row">
        <v-btn
          @click="stockOpen"
          color="indigo lighten-2"
          class="mt-1 mt-md-0"
          dark
          small
          depressed
        >
          Выбрать вручную
        </v-btn>
      </div>

      <!-- Кнопки сохранения -->
      <v-divider class="my-4"></v-divider>
      <div class="d-flex justify-end flex-column flex-md-row">
        <v-btn
          @click="resetChanges"
          :disabled="!hasSettingsChanges"
          class="mr-md-2"
          color="error"
          small
          depressed
        >
          Сбросить
        </v-btn>
        <v-btn
          @click="saveChanges"
          :disabled="!hasSettingsChanges"
          class="mt-1 mt-md-0"
          color="primary"
          small
          depressed
        >
          Сохранить изменения
        </v-btn>
      </div>

      <!-- Оверлей при обновлении -->
      <v-overlay color="white" :value="getDistributorUnitsUpdating" absolute>
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Лоадер -->
    <div v-else class="flex-grow-1 text-center py-12">
      <div class="mt-4 text-center" v-if="getStockUnitsFetching">
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="title font-weight-light">
        Выберите ед. измерения
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { scrollToTargetMixin } from '@/common/scroll/mixins'
import StockUnitTable from './StockUnitTable'

export default {
  mixins: [scrollToTargetMixin],
  components: { StockUnitTable },
  props: {
    selectedItem: Object
  },
  data: () => ({
    // Настройки привязки выбранного элемента, устанавливаются при смене элемента поставщика
    defaultSettings: {
      bindedId: null
    },
    // Изменения настроек
    changedSettings: {
      bindedId: null
    },
    // Данные для ручного выбора элемента привязки
    stockOpened: false,
    stockSelectedItemId: null,
    stockSearch: '',
    // id элемента для показа в стоке
    highlightedItemId: null
  }),
  watch: {
    // Устанавливаем значения при смене элемента поставщика
    selectedItem: function(newSelectedItem) {
      // Сбрасываем значения
      this.stockOpened = false
      this.stockSelectedItemId = null
      this.highlightedItemId = null

      if (newSelectedItem) {
        this.defaultSettings.bindedId = newSelectedItem.bind
        this.changedSettings.bindedId = newSelectedItem.bind
      } else {
        this.defaultSettings.bindedId = null
        this.changedSettings.bindedId = null
      }
    }
  },
  computed: {
    ...mapGetters('stock_units', [
      'getStockUnits',
      'getStockUnitsFetching',
      'getStockUnitById'
    ]),
    ...mapGetters('distributor_units', ['getDistributorUnitsUpdating']),
    // Настройки с учетом изменений
    settings: function() {
      return {
        ...this.defaultSettings,
        ...this.changedSettings
      }
    },
    // Наличие изменений
    hasSettingsChanges: function() {
      return !_.isEqual(this.defaultSettings, this.changedSettings)
    },
    // Получаем объект привязанного элемента
    bindedItem: function() {
      return this.settings.bindedId
        ? this.getStockUnitById(this.settings.bindedId)
        : null
    },
    // Список id подсвеченных элементов в структуре
    stockHighlightedItems: function() {
      return this.highlightedItemId ? [this.highlightedItemId] : []
    }
  },
  methods: {
    ...mapActions('distributor_units', ['actionBindDistributorUnit']),
    // Открыть ручной выбор
    stockOpen: function() {
      this.stockOpened = true
    },
    // Закрыть ручной выбор
    stockClose: function() {
      this.stockOpened = false
      this.stockSearch = ''
      this.highlightedItemId = null
    },
    // Событие ручного выбора
    stockItemSelect: function(item) {
      this.stockSelectedItemId =
        this.stockSelectedItemId !== item.id ? item.id : null
    },
    // Подтверждение ручного выбора
    stockConfirm: function() {
      this.changedSettings.bindedId = this.stockSelectedItemId
      this.stockClose()
    },
    // Показать элемент в структуре
    showInStockTable: function(item) {
      this.highlightedItemId = item.id
      this.stockOpen()
      this.tryScrollToTargetId(item.id)
    },
    // Кнопка очистки привязки
    unbindButton: function() {
      this.changedSettings.bindedId = null
    },
    // Сброс всех изменений
    resetChanges: function() {
      this.changedSettings = { ...this.defaultSettings }
    },
    // Сохранение изменений
    saveChanges: function() {
      this.actionBindDistributorUnit({
        id: this.selectedItem.id,
        bindId: this.changedSettings.bindedId || null
      })
    }
  }
}
</script>

<style scoped lang="scss">
.settings-bind-info {
  position: relative;
}
</style>

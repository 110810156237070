<template>
  <StepSkeleton :loading="cardLoading">
    <!-- Помощь -->
    <template #help-message>
      <div class="title pb-1">Список юнитов поставщика</div>
      <img src="@/assets/images/help/unit_distributor.png" class="mb-2" />
      <ol class="pb-6">
        <li>Фильтр элементов по привязке</li>
        <li>
          Иконка статуса привязки (зеленый - привязан, серый - не привязан)
        </li>
        <li>Кнопка выбора параметра для привязки</li>
      </ol>

      <div class="title pb-1">Настройки привязки</div>
      <img src="@/assets/images/help/unit_settings.png" class="mb-2" />
      <ol class="pb-6">
        <li>
          Текущий привязанный элемент. Иконка поиска открывает положение данного
          элемента в полном списке юнитов из 1с.
        </li>
        <li>Кнопка отображения полного списка юнитов из 1с.</li>
        <li>
          Изменения не сохраняются автоматически, нужно обязательно жать кнопку
          "Сохранить изменения"
        </li>
      </ol>

      <div class="title pb-1">Полный список юнитов из 1с</div>
      <img src="@/assets/images/help/unit_list.png" class="mb-2" />
      <ol>
        <li>Фильтр параметров по названию</li>
        <li>Юнит в 1с. Кнопка выбора элемента для привязки.</li>
        <li>
          "Назад" - возвращение к настройкам привязки. "Продолжить" -
          возвращение к настройкам привязки и установка выбранного элемента в
          качестве цели привязки.
        </li>
      </ol>
    </template>

    <!-- Левая часть -->
    <template #content-left>
      <!-- Выбранный элемент -->
      <div v-if="selectedItem" class="pb-6">
        <div class="subtitle-1">Выбранный элемент:</div>
        <DistributorUnitTable
          :items="selectedItemsList"
          :selectedItem="selectedItem"
          @select-item="onSelectItem"
          hide-default-footer
          disable-sort
        />
        <v-divider></v-divider>
      </div>

      <div class="mt-4 mb-2">
        <v-btn-toggle
          v-model="filter"
          class="d-flex flex-column flex-md-row"
          mandatory
          borderless
          tile
        >
          <v-btn
            :value="filters.unbinded"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Непривязанные ({{ countFilteredUnbinded }})
          </v-btn>
          <v-btn
            :value="filters.binded"
            class="flex-grow-1"
            color="success"
            outlined
            small
          >
            Привязанные ({{ countFilteredBinded }})
          </v-btn>
          <v-btn
            :value="filters.all"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Все ({{ countFilteredAll }})
          </v-btn>
        </v-btn-toggle>
      </div>

      <!-- Таблица элементов поставщика -->
      <DistributorUnitTable
        :items="filteredDistributorItems"
        :loading="getDistributorUnitsFetching || getDistributorUnitsUpdating"
        :selectedItem="selectedItem"
        @select-item="onSelectItem"
      />
    </template>

    <!-- Правая часть -->
    <template #content-right>
      <!-- Настройки выбранного элемента -->
      <Settings :selectedItem="selectedItem" />
    </template>

    <template #actions>
      <v-void />
    </template>
  </StepSkeleton>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepSkeleton from '../StepSkeleton'
import DistributorUnitTable from './DistributorUnitTable'
import Settings from './Settings'

const filters = {
  unbinded: 'unbinded',
  binded: 'binded',
  all: 'all'
}

export default {
  components: {
    StepSkeleton,
    DistributorUnitTable,
    Settings
  },
  created() {
    this.filters = filters
    // Загрузка данных при переходе по табам
    this.actionFetchStockUnits()
    if (this.activeDistributor) {
      this.actionFetchDistributorUnits(this.activeDistributor)
    }
  },
  destroyed() {
    this.actionClearStockUnits()
    this.actionClearDistributorUnits()
  },
  data: () => ({
    filter: filters.unbinded,
    selectedItemId: null
  }),
  watch: {
    // Загрузка данных при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.actionFetchDistributorUnits(distributorId)
      }
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_units', [
      'getDistributorUnits',
      'getDistributorUnitsFetching',
      'getDistributorUnitsUpdating',
      'getDistributorUnitById'
    ]),
    selectedItem: function() {
      return this.selectedItemId
        ? this.getDistributorUnitById(this.selectedItemId)
        : null
    },
    selectedItemsList: function() {
      return this.selectedItem ? [this.selectedItem] : []
    },
    // Список элементов отфильтрованный по статусу
    filteredDistributorItems: function() {
      return this.getDistributorUnits.filter(item => {
        switch (this.filter) {
          case filters.binded:
            return item.bind !== null
          case filters.unbinded:
            return item.bind === null
        }
        return true
      })
    },
    // Количество элементов
    countFilteredAll: function() {
      return this.getDistributorUnits.length
    },
    // Количество привязанных элементов
    countFilteredBinded: function() {
      return this.getDistributorUnits.filter(item => item.bind !== null).length
    },
    // Количество непривязанных элементов
    countFilteredUnbinded: function() {
      return this.getDistributorUnits.filter(item => item.bind === null).length
    },
    cardLoading: function() {
      return this.getDistributorUnitsFetching
    }
  },
  methods: {
    ...mapActions('distributor_units', [
      'actionFetchDistributorUnits',
      'actionClearDistributorUnits'
    ]),
    ...mapActions('stock_units', [
      'actionFetchStockUnits',
      'actionClearStockUnits'
    ]),
    // Выбора элемента
    onSelectItem: function(item) {
      this.selectedItemId = this.selectedItemId === item.id ? null : item.id
    }
  }
}
</script>

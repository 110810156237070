<template>
  <StepSkeleton :loading="cardLoading">
    <!-- Помощь -->
    <template #help-message>
      <div class="title pb-1">Привязка директорий</div>
      <div class="pb-6">
        Нужно привязать директории поставщика к папкам номенклатуры, веткам
        ассортимента, товарным направлениям из 1с. Привязка нужна для
        дальнейшего создания товаров поставщика в дереве номенклатуры 1с.
      </div>

      <div class="title pb-1">Дерево категорий поставщика</div>
      <img src="@/assets/images/help/catalog_distributor.png" class="mb-2" />
      <ol class="pb-6">
        <li>Элемент каталога</li>
        <li>Количество товаров к категории</li>
        <li>
          Иконка наличия настроек привязки и автоматического создания товаров
          (зеленый - привязан, серый - не привязан)
        </li>
        <li>Иконка наследования настроек от родительского элемента</li>
      </ol>

      <div class="title pb-1">
        Тип обработки дочерних несвязанных папок и товаров
      </div>
      <img src="@/assets/images/help/catalog_settings_type.png" class="mb-2" />
      <ol class="pb-6">
        <li>Настройки обработки наследуются от родительского элемента</li>
        <li>
          Непривязанные папки и товары создаются в указанной папке номенклатуры
          (вложенность папок поставщика сохраняется)
        </li>
        <li>
          Все непривязанные вложенные товары создаются в одной указанной папке
          номенклатуры (вложенные папки не создаются)
        </li>
      </ol>

      <div class="title pb-1">
        Привязка к папке номенклатуры, ветке ассортимента, товарному направлению
      </div>
      <img src="@/assets/images/help/catalog_settings_bind.png" class="mb-2" />
      <ol class="pb-6">
        <li>
          Текущий привязанный элемент. Иконка поиска открывает положение данного
          элемента в полном дереве элементов из 1с.
        </li>
        <li>
          Подходящие элементы для привязки. Иконка поиска открывает положение
          данного элемента в полном дереве элементов из 1с.
        </li>
        <li>Кнопка отображения полного дерева элементов из 1с.</li>
      </ol>

      <div class="title pb-1">Полное дерево элементов из 1с</div>
      <img src="@/assets/images/help/catalog_settings_tree.png" class="mb-2" />
      <ol class="pb-6">
        <li>Фильтр по названию</li>
        <li>Элемент для привязки</li>
        <li>Кнопка выбора элемента в качестве цели для привязки</li>
        <li>
          Кнопка для создания нового элемента и дальнейшей привязки к нему
          (только для папок номенклатуры)
        </li>
        <li>
          "Назад" - возвращение к настройкам привязки. "Продолжить" -
          возвращение к настройкам привязки и установка выбранного элемента в
          качестве цели привязки.
        </li>
      </ol>
    </template>

    <!-- Левая часть -->
    <template #content-left>
      <!-- Дерево категорий поставщика -->
      <DistributorCatalogs :onSelect="onCategorySelect" />
    </template>

    <!-- Правая часть -->
    <template #content-right>
      <!-- Настройки выбранного бренда -->
      <Settings :distributorCategory="selectedCategory" />
    </template>

    <template #actions>
      <v-void />
    </template>
  </StepSkeleton>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepSkeleton from '../StepSkeleton'
import DistributorCatalogs from './DistributorCatalogs'
import Settings from './Settings'

export default {
  components: {
    StepSkeleton,
    DistributorCatalogs,
    Settings
  },
  created() {
    // Загрузка веток ассртимента
    this.actionFetchStockTree()
    // Загрузка категорий stock
    this.actionFetchStockCategories()
    // Загрузка направлений
    this.actionFetchStockDirections()
    // Загрузка категорий поставщика при переходе по табам
    if (this.activeDistributor) {
      this.actionFetchAllDistributorCategories(this.activeDistributor)
      this.actionFetchDistributorBrands(this.activeDistributor)
      this.actionFetchSelectedStockCategories(this.activeDistributor)
      this.actionFetchSelectedStockTree(this.activeDistributor)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.actionClearStockDirections()
    this.actionClearStockTree()
    this.actionClearStockCategories()
    this.actionClearDistributorCategories()
    this.actionClearDistributorBrands()
    next()
  },
  data: () => ({
    selectedCategoryId: null
  }),
  watch: {
    // Загрузка категорий при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.actionFetchSelectedDistributorCategories(distributorId)
      }
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_categories', [
      'getDistributorCategoriesFetching',
      'getDistributorCategoryById'
    ]),
    ...mapGetters('stock_categories', [
      'getStockCategoriesFetching',
      'getStockCategoriesSelectedFetching',
      'getStockCategoriesAdding',
    ]),
    ...mapGetters('stock_tree', [
      'getStockTreeFetching',
      'getStockTreeSelectedFetching',
      'getStockTreeAdding'
    ]),
    ...mapGetters('stock_directions', ['getStockDirectionsFetching']),
    selectedCategory: function() {
      return this.selectedCategoryId
        ? this.getDistributorCategoryById(this.selectedCategoryId)
        : null
    },
    cardLoading: function() {
      return (
        this.getDistributorCategoriesFetching ||
        this.getStockCategoriesFetching ||
        this.getStockTreeFetching ||
        this.getStockCategoriesAdding ||
        this.getStockTreeAdding ||
        this.getStockDirectionsFetching ||
        this.getStockTreeSelectedFetching ||
        this.getStockCategoriesSelectedFetching
      )
    }
  },
  methods: {
    ...mapActions('distributor_categories', [
      'actionClearDistributorCategories',
      'actionFetchSelectedDistributorCategories',
      'actionFetchAllDistributorCategories',
    ]),
    ...mapActions('distributor_brands', [
      'actionFetchDistributorBrands',
      'actionClearDistributorBrands'
    ]),
    ...mapActions('stock_categories', [
      'actionFetchStockCategories',
      'actionClearStockCategories',
      'actionFetchSelectedStockCategories'
    ]),
    ...mapActions('stock_tree', [
      'actionFetchStockTree',
      'actionFetchSelectedStockTree',
      'actionClearStockTree'
    ]),
    ...mapActions('stock_directions', [
      'actionFetchStockDirections',
      'actionClearStockDirections'
    ]),
    // Выбор категории поставщика
    onCategorySelect: function(item) {
      this.selectedCategoryId = item ? item.id : null
    }
  }
}
</script>

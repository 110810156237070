<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="distributorListFetching"
    :show-expand="showExpand"
    disable-sort
  >
    <template #item.status="{ item }">
      <v-dialog max-width="600" v-model="showErrorDialog" scrollable>
        <template v-slot:activator="{ on }">
          <v-chip :color="statusColor(item)" small label v-on="on">
            {{ statusName(item) }}
          </v-chip>
        </template>
        <template v-slot>
          <v-card>
            <v-card-title>Ошибки, возникшие при последнем запуске парсера</v-card-title>
            <v-card-text class="distributor_error_messages">{{ item.last_errors }}</v-card-text>
            <v-card-actions>
              <v-btn class="ml-auto grey--text" outlined @click="showErrorDialog = false">Закрыть</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template #item.updated_at="{ item }">
      {{ item.updated_at | datetime }}
    </template>
    <template #item.enabled="{ item }">
      <v-switch
        v-model="item.enabled"
        :disabled="item.loading || item.processing || !editable"
        :loading="item.loading"
        @change="switchItem(item)"
      ></v-switch>
    </template>
    <template #item.select="{ item }">
      <v-btn
        :disabled="item.processing || item.loading"
        :to="{
          name: routes.aggregatorStepSelectCatalogs,
          params: { id: item.id }
        }"
        color="primary"
        small
        depressed
      >
        Выбрать
      </v-btn>
    </template>
    <template #expanded-item="{ headers, item }">
      <td v-if="showExpand" :colspan="headers.length">
        <DistributorTableManagers :item="item" :managers="managers" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { routes } from '@/router'
import permissions from '@/common/permissions'
import DistributorTableManagers from './DistributorTableManagers'

export default {
  components: { DistributorTableManagers },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    managers: Array
  },
  data: () => ({
    showErrorDialog: false
  }),
  created() {
    this.routes = routes
    this.headers = [
      {
        text: 'Название',
        value: 'name',
        width: '50%'
      },
      {
        text: 'Статус обработки',
        value: 'status',
        align: 'center'
      },
      {
        text: 'Активность',
        value: 'enabled',
        align: 'center'
      },
      {
        text: 'Время последней синхронизации',
        value: 'updated_at',
        align: 'center'
      },
      {
        text: '',
        value: 'select',
        align: 'center'
      }
    ]
  },
  computed: {
    ...mapGetters('auth', [
      'currentUser',
      'getUsersFetching',
      'getCurrentUserHasPermission'
    ]),
    ...mapGetters('distributor', [
      'distributorListTable',
      'distributorListFetching'
    ]),
    items: function() {
      return this.distributorListTable.filter(item => {
        return (
          this.currentUser &&
          (this.getCurrentUserHasPermission(permissions.appAggregatorAdmin) ||
            (item.managers && item.managers.includes(this.currentUser.id)))
        )
      })
    },
    showExpand: function() {
      return (
        this.currentUser &&
        this.getCurrentUserHasPermission(permissions.appAggregatorAdmin)
      )
    }
  },
  methods: {
    ...mapActions('distributor', ['actionSwitchDistributor']),
    switchItem: function(item) {
      this.actionSwitchDistributor({
        id: item.id,
        enabled: item.enabled
      })
    },
    statusColor: function(item) {
      return item.last_errors ? 'error' : 'success'
    },
    statusName: function(item) {
      return item.last_errors ? 'Есть ошибки' : 'Успешно'
    }
  },
  filters: {
    datetime: function(value) {
      return value ? new Date(value).toLocaleString() : '------'
    }
  }
}
</script>
<style>
.distributor_error_messages {
  white-space: pre-line;
}
</style>
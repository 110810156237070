<template>
  <v-container>
    <v-row>
      <!-- Название поставщика -->
      <v-col cols="12" md="8" order="1" order-md="0">
        <div class="breadcrumbs subtitle-1 pt-2">
          <router-link :to="{ name: routes.aggregatorSelectDistributor }">
            Поставщики
          </router-link>
          <span class="blue-grey--text lighten-2 pl-2 pr-1">/</span>
          <span v-if="!distributorListFetching" class="headline">
            {{ activeDistributorTitle }}
          </span>
          <span v-else class="pl-1">
            <v-progress-circular
              :size="24"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="pl-3">Загрузка...</span>
          </span>
        </div>
      </v-col>

      <!-- Список смены поставщика -->
      <v-col cols="12" md="4" order="0" order-md="1">
        <v-select
          :value="activeDistributor"
          :items="distributorListSelect"
          :loading="distributorListFetching"
          @change="changeActiveDistributor"
          label="Выбранный поставщик"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <!-- Кнопки перехода по этапам -->
    <v-row>
      <v-col class="py-0">
        <v-card>
          <v-tabs center-active show-arrows grow>
            <v-tab
              :to="{ name: routes.aggregatorStepSelectCatalogs }"
              :disabled="distributorListFetching"
              exact
              link
            >
              Выбор каталогов
            </v-tab>
            <v-tab
              :to="{ name: routes.aggregatorStepBindBrands }"
              :disabled="distributorListFetching"
              exact
              link
            >
              <v-badge
                color="red"
                dot
                :value="$store.state.app_aggregator.active_distributor_unbind_counters.brands"
              >Привязка брендов</v-badge>
            </v-tab>
            <v-tab
              :to="{ name: routes.aggregatorStepBindFeatures }"
              :disabled="distributorListFetching"
              exact
              link
            >
              <v-badge
                color="red"
                dot
                :value="$store.state.app_aggregator.active_distributor_unbind_counters.features"
              >Привязка параметров</v-badge>
            </v-tab>
            <v-tab
              :to="{ name: routes.aggregatorStepBindUnits }"
              :disabled="distributorListFetching"
              exact
              link
            >
              <v-badge
                color="red"
                dot
                :value="$store.state.app_aggregator.active_distributor_unbind_counters.units"
              >Привязка единиц измерения</v-badge>
            </v-tab>
            <v-tab
              :to="{ name: routes.aggregatorStepBindCatalogs }"
              :disabled="distributorListFetching"
              exact
              link
            >
              Привязка каталогов
            </v-tab>
            <v-tab
              :to="{ name: routes.aggregatorStepBindProducts }"
              :disabled="distributorListFetching"
              exact
              link
            >
              <v-badge
                color="red"
                dot
                :value="$store.state.app_aggregator.active_distributor_unbind_counters.products"
              >Привязка товаров</v-badge>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <!-- Контент -->
    <v-row>
      <v-col class="pt-6">
        <span id="top-content-anchor"></span>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { routes } from '@/router'

export default {
  props: {
    id: Number
  },
  created() {
    this.routes = routes
    this.actionSelectDistributor(this.id)
  },
  watch: {
    $route(to) {
      if (this.activeDistributor != to.params.id) {
        this.actionSelectDistributor(Number(to.params.id))
      }
    }
  },
  computed: {
    ...mapGetters('distributor', [
      'distributorListSelect',
      'distributorListFetching',
      'getDistributorById'
    ]),
    ...mapGetters('app_aggregator', ['activeDistributor']),
    activeDistributorTitle() {
      const distributorData = this.getDistributorById(this.activeDistributor)
      return distributorData ? distributorData.name : ''
    }
  },
  methods: {
    ...mapActions('app_aggregator', ['actionSelectDistributor']),
    changeActiveDistributor(id) {
      this.$router
        .push({
          name: routes.aggregatorStepSelectCatalogs,
          params: { id }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  > * {
    vertical-align: middle;
  }
  a {
    text-decoration: none;
  }
}
</style>

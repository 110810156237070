<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container pa-0 fluid>
      <v-row>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="distributor"
            label="Поставщик"
            autocomplete="off"
            :items="distributorListSearch"
            :loading="distributorListSearchFetching"
            :rules="selectRules"
            :search-input.sync="search"
            hide-no-data
            dense
            outlined
            required
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="parser"
            label="Парсер"
            autocomplete="off"
            :items="parserList"
            :loading="parserListFetching"
            :rules="selectRules"
            dense
            outlined
            required
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            v-model="selectedManagers"
            label="Менеджеры"
            :items="managers"
            :loading="getUsersFetching"
            multiple
            dense
            outlined
            required
          >
            <template #selection="{ item, index }">
              <div
                v-if="selectedManagers.length === 1"
                class="v-select__selection v-select__selection--comma"
              >
                {{ item.text }}
              </div>
              <div
                v-if="index === 0 && selectedManagers.length > 1"
                class="v-select__selection v-select__selection--comma"
              >
                {{ selectedManagers.length }} / {{ managers.length }}
              </div>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn
            type="submit"
            color="success"
            :disabled="formButtonDisabled"
            :loading="distributorAdding"
            block
          >
            Создать
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    managers: Array
  },
  data: () => ({
    distributor: '',
    parser: '',
    selectRules: [v => !!v || 'Обязательно для заполнения'],
    search: null,
    selectedManagers: []
  }),
  mounted() {
    this.actionFetchParserList()
  },
  watch: {
    search(v) {
      if (v && v.trim().length > 0 && !this.distributor) {
        this.actionFetchDistributorListSearch(v)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUsersFetching']),
    ...mapGetters('distributor', [
      'distributorListSearch',
      'distributorListSearchFetching',
      'distributorAdding',
      'parserList',
      'parserListFetching'
    ]),
    formButtonDisabled: function() {
      return this.distributorListSearchFetching || this.distributorListFetching
    }
  },
  methods: {
    ...mapActions('distributor', [
      'actionFetchDistributorListSearch',
      'actionFetchParserList',
      'actionAddDistributor'
    ]),
    submit: function() {
      if (this.$refs.form.validate()) {
        this.actionAddDistributor({
          distributor_id: this.distributor,
          parser_id: this.parser,
          managers: this.selectedManagers
        }).then(() => {
          this.$refs.form.reset()
        })
      }
    }
  }
}
</script>

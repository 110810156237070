<template>
  <div>
    <!-- Спинер -->
    <div class="my-4 text-center" v-if="getDistributorCategoriesFetching">
      <slot name="loader"></slot>
    </div>
    <div v-else>
      <v-overlay
        color="white"
        :value="getDistributorCategoriesUpdating"
        absolute
      >
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <div class="subtitle-1 font-weight-medium">Выберите нужные разделы:</div>

      <!-- Чекбокс вкл/выкл всех -->
      <v-checkbox
        label="Все элементы"
        class="mt-1"
        @change="switchAll"
        dense
        hide-details
      ></v-checkbox>
      <v-treeview
        :items="getDistributorRootCategories"
        :active="activeItems"
        selection-type="independent"
        class="pb-3 pt-2"
        multiple-active
        hoverable
        open-on-click
        dense
      >
        <template #prepend="{ item }">
          <SelectTreeChildsDialog @select="checkboxHandler(item, $event)">
            <template v-slot:activator="{on, attrs}">
              <v-checkbox
                :input-value="item.selected"
                class="ma-0 pa-0 tree_checkbox"
                hide-details
                dense
                v-on="on"
                v-bind="attrs"
              ></v-checkbox>
            </template>
          </SelectTreeChildsDialog>
        </template>
        <template #label="{ item }">
          {{ item.name }}
          <span class="font-weight-thin">
            {{ item.products_count ? `(${item.products_count})` : '' }}
          </span>
        </template>
      </v-treeview>
      <slot
        name="actions"
        :resetChanges="resetChanges"
        :saveChanges="saveChanges"
        :hasChanges="hasChanges"
      ></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import SelectTreeChildsDialog from "@/components/SelectTreeChildsDialog";

const itemIsActive = item => {
  if (item.selected) {
    return true
  } else if (item.children) {
    return item.children.some(itemIsActive)
  }
  return false
}

export default {
  components: {
    SelectTreeChildsDialog
  },
  mounted() {
    // Загрузка категорий при переходе по табам
    if (this.activeDistributor) {
      this.actionFetchAllDistributorCategories(this.activeDistributor)
    }
  },
  destroyed() {
    this.actionClearDistributorCategories()
  },
  watch: {
    $route() {
      this.actionClearDistributorCategories()
    },
    // Загрузка категорий при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.actionFetchAllDistributorCategories(distributorId)
      }
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_categories', [
      'getDistributorRootCategories',
      'getDistributorCategoriesTree',
      'getDistributorCategoriesFetching',
      'getDistributorCategoriesUpdating',
      'getDistributorCategoryChildsIdRecursive',
      'getDistributorCategoriesSelected'
    ]),
    activeItems: function() {
      return this.getDistributorCategoriesTree.length
        ? this.getDistributorCategoriesTree
            .filter(item => itemIsActive(item))
            .map(item => item.id)
        : []
    },
    selectedItems: function() {
      return (
        this.getDistributorCategoriesSelected.changed ||
        this.getDistributorCategoriesSelected.default
      )
    },
    hasChanges: function() {
      return this.getDistributorCategoriesSelected.changed
        ? !_.isEqual(
            _.sortBy(this.getDistributorCategoriesSelected.changed),
            _.sortBy(this.getDistributorCategoriesSelected.default)
          )
        : false
    }
  },
  methods: {
    ...mapActions('distributor_categories', [
      'actionFetchAllDistributorCategories',
      'actionSwitchDistributorCategories',
      'actionClearDistributorCategories',
      'actionSetDistributorCategoriesSelected'
    ]),
    checkboxHandler: function(item, selectChilds) {
      let checked = this.selectedItems.includes(item.id)
      let selectedItems = []
      if (selectChilds) {
        selectedItems = checked
          ? _.difference(this.selectedItems, [
              item.id,
              ...this.getDistributorCategoryChildsIdRecursive(item.id)
            ])
          : _.concat(this.selectedItems, [
              item.id,
              ...this.getDistributorCategoryChildsIdRecursive(item.id)
            ])
      } else {
        selectedItems = checked
          ? _.difference(this.selectedItems, [item.id])
          : _.concat(this.selectedItems, [item.id])
      }
      this.actionSetDistributorCategoriesSelected({ changed: selectedItems })
    },
    // Кнопка отмена изменений
    resetChanges: function() {
      this.actionSetDistributorCategoriesSelected({ changed: null })
    },
    // Кнопка сохранить изменения
    saveChanges: function() {
      this.actionSwitchDistributorCategories({
        toggleOn: _.difference(
          this.getDistributorCategoriesSelected.changed,
          this.getDistributorCategoriesSelected.default
        ),
        toggleOff: _.difference(
          this.getDistributorCategoriesSelected.default,
          this.getDistributorCategoriesSelected.changed
        )
      }).then(() => {
        this.actionSetDistributorCategoriesSelected({
          default: this.getDistributorCategoriesSelected.changed,
          changed: null
        })
      })
    },
    // Вкл/Выкл все записи
    switchAll: function(value) {
      this.actionSetDistributorCategoriesSelected({
        changed: value
          ? this.getDistributorCategoriesTree.map(item => item.id)
          : null
      })
    }
  }
}
</script>

<style>
  .tree_checkbox input {
    display: none !important;
  }
</style>
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :hide-default-footer="hideDefaultFooter"
      :disable-sort="disableSort"
      class="thin-table"
      dense
    >
      <template #item.enabled="{ item }">
        <v-switch
          :input-value="item.enabled"
          :disabled="item.loading"
          :loading="item.loading"
          @change="switchItem(item)"
          class="my-3 ml-3"
          hide-details
        ></v-switch>
      </template>
      <template #item.show_values="{ item }">
        <v-badge color="red" dot :value="item.unbinded_values" v-if="item.bind">
          <v-btn @click.stop="showFeatureValues(item.id)" small depressed>
            Значения
          </v-btn>
        </v-badge>
        <v-btn @click.stop="showFeatureValues(item.id)" small depressed v-else>
          Значения
        </v-btn>
      </template>
      <template #item.select="{ item }">
        <v-btn
          :color="buttonColor(item)"
          @click="$emit('select-item', item)"
          small
          depressed
        >
          {{ buttonText(item) }}
        </v-btn>
      </template>
      <template #item.status="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon :color="linkColor(item)" v-on="on">
              mdi-link-box-variant
            </v-icon>
          </template>
          <span>{{ linkTooltipText(item) }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Модальное окно со списком значений поставщика -->
    <v-dialog v-model="featureValues.display" width="900" scrollable>
      <v-card>
        <v-card-title>
          <span class="subtitle-1">
            Значения параметра
          </span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>Кол-во товаров <u>поставщика</u> использующих значение</th>
                  <th>Оригинальное значение <u>поставщика</u></th>
                  <th>Привязанное значение <u>ЦСМ</u></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in featureValues.items" :key="index">
                  <td class="text-center">{{ item.used_in_products }}</td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.bind_value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-overlay
            color="white"
            :value="featureValues.requestInProgress"
            absolute
          >
            <v-progress-circular
              :size="50"
              color="indigo lighten-4"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              @click="featureValues.display = false"
              color="grey darken-1"
              outlined
            >
              Закрыть
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import distributorFeaturesApi from '@/services/distributorFeaturesApi'
import stockFeaturesApi from '@/services/stockFeaturesApi'

export default {
  props: {
    items: Array,
    loading: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    selectedItem: Object
  },
  data: () => ({
    featureValues: {
      display: false,
      items: [],
      requestInProgress: false
    }
  }),
  created() {
    this.headers = [
      {
        text: 'Название',
        value: 'name',
        width: '100%'
      },
      {
        text: 'Активность',
        value: 'enabled',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Статус',
        value: 'status',
        sortable: false,
        align: 'center'
      },
      {
        text: '',
        value: 'show_values',
        sortable: false,
        align: 'center'
      },
      {
        text: '',
        value: 'select',
        sortable: false
      }
    ]
  },
  computed: {
    selectedItemId: function() {
      return this.selectedItem && this.selectedItem.id
        ? this.selectedItem.id
        : null
    }
  },
  methods: {
    ...mapActions('distributor_features', ['actionSwitchDistributorFeature']),
    // Цвет иконки привязки
    linkColor: function(item) {
      return item.bind !== null ? 'success' : 'grey darken-3'
    },
    // Текст тултипа иконки привязки
    linkTooltipText: function(item) {
      return item.bind !== null ? 'Связано' : 'Не связано'
    },
    // Цвет кнопки выбора
    buttonColor: function(item) {
      return this.selectedItemId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки выбора
    buttonText: function(item) {
      return this.selectedItemId === item.id ? 'Выбрано' : 'Выбрать'
    },
    // Вкл/выкл параметр
    switchItem: function(item) {
      this.actionSwitchDistributorFeature({
        id: item.id,
        enabled: !item.enabled
      })
    },
    // Получение значений свойств
    showFeatureValues: function(featureId) {
      this.featureValues = {
        ...this.featureValues,
        display: true,
        items: [],
        requestInProgress: true
      }
      const distributorFeature = this.items.find(item => item.id === featureId)
      const promiseTasks = [distributorFeaturesApi.getFeatureValues(featureId)]
      if (distributorFeature.bind) {
        promiseTasks.push(
          stockFeaturesApi.getStockFeatureValues(distributorFeature.bind)
        )
      }
      Promise.all(promiseTasks)
        .then(([distributorValues, stockValues]) => {
          let displayItem = distributorValues
          if (stockValues && stockValues.length) {
            displayItem = distributorValues.map(distributorValueItem => {
              const bindedItem = stockValues.find(
                stockValueItem =>
                  stockValueItem.id === distributorValueItem.bind
              )
              return distributorValueItem.bind
                ? {
                    ...distributorValueItem,
                    bind_value: bindedItem && bindedItem.value
                  }
                : distributorValueItem
            })
          }
          this.featureValues.items = _.sortBy(displayItem, ['value'])
        })
        .finally(() => {
          this.featureValues.requestInProgress = false
        })
    }
  }
}
</script>

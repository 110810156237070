<template>
  <StepSkeleton :loading="cardLoading">
    <!-- Помощь -->
    <template #help-message>
      <div class="title pb-1">Привязка брендов</div>
      При нажатии на кнопку "Автоматическая привязка" агрегатор попробует привязать бренды
      автоматически. На данный момент, критерием привязки является полное совпадение имени.
      Привязываться будут только активные бренды.
      <div class="pb-6">
        На следующих вкладках (этапах агрегации) будут отображаться данные только по привязанным
        брендам.
      </div>

      <div class="title pb-1">Список брендов поставщика</div>
      <img src="@/assets/images/help/brand_distributor.png" class="mb-2" />
      <ol class="pb-6">
        <li>Фильтр элементов по активности</li>
        <li>Фильтр элементов по привязке</li>
        <li>
          Виджет вкл/выкл бренда. Бренд можно выключить чтобы отфильтровать
          ненужные элементы
        </li>
        <li>
          Иконка статуса привязки (зеленый - привязан, серый - не привязан)
        </li>
        <li>Кнопка выбора бренда для привязки</li>
      </ol>

      <div class="title pb-1">Настройки привязки</div>
      <img src="@/assets/images/help/brand_settings.png" class="mb-2" />
      <ol class="pb-6">
        <li>
          Текущий привязанный элемент. Иконка поиска открывает положение данного
          элемента в полном дереве брендов из 1с.
        </li>
        <li>
          Возможный элемент для привязки, клик для привязки. Иконка поиска
          открывает положение данного элемента в полном дереве брендов из 1с.
        </li>
        <li>
          Кнопка отображения полного дерева брендов из 1с для выбора привязки
          вручную
        </li>
        <li>
          Изменения не сохраняются автоматически, нужно обязательно жать кнопку
          "Сохранить изменения"
        </li>
      </ol>

      <div class="title pb-1">Полное дерево брендов из 1с</div>
      <img src="@/assets/images/help/brand_tree.png" class="mb-2" />
      <ol>
        <li>Фильтр брендов по названию</li>
        <li>Бренд в 1с. Кнопка выбора элемента для привязки.</li>
        <li>
          "Продолжить" - возвращение к настройкам привязки и установка
          выбранного элемента в качестве цели привязки.
        </li>
      </ol>
    </template>

    <template #toolbar-left>
       <v-btn @click="requestAutoBind" depressed small>Автоматическая привязка</v-btn>
      <v-dialog v-model="autobindDialog" width="300" persistent>
        <v-card color="primary" dark>
          <v-card-text class="pa-3">
            <div v-if="autobindingRequestInProgress">
              Обработка брендов
              <v-progress-linear
                color="white"
                class="my-3"
                indeterminate
              ></v-progress-linear>
            </div>
            <div v-else class="d-flex">
              <div>Привязано брендов: {{ autobindResult }}</div>
              <v-btn
                @click.stop="autobindDialog = false"
                color="success"
                class="ml-auto"
                x-small
                depressed
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <!-- Левая часть -->
    <template #content-left>
      <!-- Выбранный элемент -->
      <div v-if="selectedBrand" class="pb-6">
        <div class="subtitle-1">Выбранный элемент:</div>
        <DistributorBrandTable
          :items="selectedBrandList"
          :selectedItem="selectedBrand"
          @select-item="onSelectBrand"
          hide-default-footer
          disable-sort
        />
        <v-divider></v-divider>
      </div>

      <!-- Фильтр -->
      <v-checkbox
        v-model="filterShowEnabled"
        :label="`Показывать активные (${countEnabled})`"
        class="mt-0"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="filterShowDisabled"
        :label="`Показывать неактивные (${countDisabled})`"
        class="mt-1"
        hide-details
      ></v-checkbox>

      <div class="mt-4 mb-2">
        <v-btn-toggle
          v-model="filter"
          class="d-flex flex-column flex-md-row"
          mandatory
          borderless
          tile
        >
          <v-btn
            :value="filters.unbinded"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Непривязанные ({{ countFilteredUnbinded }})
          </v-btn>
          <v-btn
            :value="filters.binded"
            class="flex-grow-1"
            color="success"
            outlined
            small
          >
            Привязанные ({{ countFilteredBinded }})
          </v-btn>
          <v-btn
            :value="filters.all"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Все ({{ countFilteredAll }})
          </v-btn>
        </v-btn-toggle>
      </div>

      <!-- Таблица брендов поставщика -->
      <DistributorBrandTable
        :items="filteredDistributorBrands"
        :selectedItem="selectedBrand"
        :loading="getDistributorBrandsFetching || getDistributorBrandsUpdating"
        @select-item="onSelectBrand"
      />
    </template>

    <!-- Правая часть -->
    <template #content-right>
      <!-- Настройки выбранного бренда -->
      <Settings :selectedBrand="selectedBrand" />
    </template>

    <template #actions>
      <v-void />
    </template>
  </StepSkeleton>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepSkeleton from '../StepSkeleton'
import DistributorBrandTable from './DistributorBrandTable'
import Settings from './Settings'
import distributorBrandsApi from "@/services/distributorBrandsApi";

const filters = {
  unbinded: 'unbinded',
  binded: 'binded',
  all: 'all'
}

export default {
  components: {
    StepSkeleton,
    DistributorBrandTable,
    Settings
  },
  created() {
    this.filters = filters
    // Загрузка брендов при переходе по табам
    this.actionFetchStockBrands()
    if (this.activeDistributor) {
      this.actionFetchDistributorBrands(this.activeDistributor)
      this.actionFetchDistributorAttachedBrands(this.activeDistributor)
    }
  },
  destroyed() {
    this.actionClearDistributorBrands()
    this.actionClearStockBrands()
  },
  data: () => ({
    filter: filters.unbinded,
    filterShowEnabled: true,
    filterShowDisabled: false,
    selectedBrandId: null,
    autobindDialog: false,
    autobindResult: 0,
    autobindingRequestInProgress: false,
  }),
  watch: {
    // Загрузка брендов при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.actionFetchDistributorBrands(distributorId)
        this.actionFetchDistributorAttachedBrands(distributorId)
      }
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_brands', [
      'getDistributorBrands',
      'getDistributorBrandsFetching',
      'getDistributorBrandsUpdating',
      'getDistributorBrandById'
    ]),
    // Получение объекта выбранного бренда
    selectedBrand: function() {
      return this.selectedBrandId
        ? this.getDistributorBrandById(this.selectedBrandId)
        : null
    },
    selectedBrandList: function() {
      return this.selectedBrand ? [this.selectedBrand] : []
    },
    // Список брендов отфильтрованный по активности
    distributorBrandsFilteredByEnabled: function() {
      return this.getDistributorBrands.filter(
        item =>
          (this.filterShowEnabled && item.enabled) ||
          (this.filterShowDisabled && !item.enabled)
      )
    },
    // Список брендов отфильтрованный по привязке
    filteredDistributorBrands: function() {
      return this.distributorBrandsFilteredByEnabled.filter(item => {
        switch (this.filter) {
          case filters.binded:
            return item.bind !== null
          case filters.unbinded:
            return item.bind === null
        }
        return true
      })
    },
    // Количество брендов с учетом фильтра по активности
    countFilteredAll: function() {
      return this.distributorBrandsFilteredByEnabled.length
    },
    // Количество привязанных брендов с учетом фильтра по активности
    countFilteredBinded: function() {
      return this.distributorBrandsFilteredByEnabled.filter(
        item => item.bind !== null
      ).length
    },
    // Количество непривязанных брендов с учетом фильтра по активности
    countFilteredUnbinded: function() {
      return this.distributorBrandsFilteredByEnabled.filter(
        item => item.bind === null
      ).length
    },
    // Количество активных
    countEnabled: function() {
      return this.getDistributorBrands.filter(item => item.enabled).length
    },
    // Количество неактивных
    countDisabled: function() {
      return this.getDistributorBrands.length - this.countEnabled
    },
    cardLoading: function() {
      return this.getDistributorBrandsFetching
    }
  },
  methods: {
    ...mapActions('distributor', ['actionFetchDistributorAttachedBrands']),
    ...mapActions('distributor_brands', [
      'actionFetchDistributorBrands',
      'actionClearDistributorBrands',
      'actionFetchBrandBindings'
    ]),
    ...mapActions('stock_brands', [
      'actionFetchStockBrands',
      'actionClearStockBrands'
    ]),
    // Событие выбора бренда
    onSelectBrand: function(item) {
      if (this.selectedBrandId === item.id) {
        this.selectedBrandId = null
      } else {
        if (item.binding_items) {
          this.selectedBrandId = item.id
        } else {
          this.actionFetchBrandBindings(item.id).then(() => {
            this.selectedBrandId = item.id
          })
        }
      }
    },
    // Автопривязка
    requestAutoBind() {
      this.autobindingRequestInProgress = true
      this.autobindDialog = true
      this.autobindResult = 0

      distributorBrandsApi
        .autobindDistributorBrands(this.activeDistributor)
        .then(data => {
          this.autobindResult = data && data.count ? data.count : 0

          if (this.autobindResult) {
            this.selectedBrandId = null
            this.actionFetchStockBrands()

            if (this.activeDistributor) {
              this.actionFetchDistributorBrands(this.activeDistributor)
              this.actionFetchDistributorAttachedBrands(this.activeDistributor)
            }
          }
        })
        .finally(() => {
          this.autobindingRequestInProgress = false
        })
    }
  }
}
</script>

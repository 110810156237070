<template>
  <v-dialog scrollable max-width="1400" v-model="show" :persistent="applying">
    <template #activator="{on, attrs}">
      <v-btn
          depressed
          small
          class="mr-2"
          title="Автоматическая привязка дочерних каталогов"
          v-on="on"
          v-bind="attrs"
      >
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>
    </template>
    <template #default>
      <v-card :loading="applying">
        <v-card-title>Варианты привязки дочерних каталогов</v-card-title>
        <v-card-text>
          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="items"
              dense
              disable-pagination
              hide-default-footer
          >
            <template #no-data>
              <div class="pa-6">Варианты привязки не найдены...</div>
            </template>
            <template #loading>
              <div class="pa-6">Подборка вариантов...</div>
            </template>
            <template #item.distributor="{item: {id}}">
              {{ getDistributorCategoryById(id).name }}
            </template>
            <template #item.structure="{item}">
              <div class="d-flex justify-space-between">
                <v-select
                    :disabled="applying"
                    dense
                    v-model="item.selected_structure"
                    :items="item.suggestions.structures"
                    v-if="item.suggestions.structures.length > 1"
                >
                  <template #item="{item}">{{ getStockCategoryById(item).name }}</template>
                  <template #selection="{item}">
                    <div v-if="item">{{ getStockCategoryById(item).name }}</div>
                  </template>
                </v-select>
                <span v-else>
                  {{ getStockCategoryById(item.suggestions.structures[0]).name }}
                </span>

                <VariantChangeItemDialog
                    @choiced="choiseCustomVariant($event, item, CATEGORY_TYPES.CATEGORY)"
                    :category-type="CATEGORY_TYPES.CATEGORY"
                ></VariantChangeItemDialog>
              </div>
            </template>
            <template #item.category="{item}">
              <div class="d-flex justify-space-between">
                <v-select
                  dense
                  :disabled="applying"
                  v-model="item.selected_category" :items="item.suggestions.categories"
                  v-if="item.suggestions.categories.length > 1"
                >
                  <template #item="{item}">{{ getStockTreeById(item).name }}</template>
                  <template #selection="{item}">
                    <div v-if="item">{{ getStockTreeById(item).name }}</div>
                  </template>
                </v-select>
                <span v-else-if="item.suggestions.categories.length === 1">
                  {{ getStockTreeById(item.suggestions.categories[0]).name }}
                </span>
                <span v-else-if="forCategory && forCategory.bind_category">
                  {{ getStockTreeById(forCategory.bind_category).name }} (Наследуется)
                </span>

                <VariantChangeItemDialog
                    :category-type="CATEGORY_TYPES.TREE"
                    @choiced="choiseCustomVariant($event, item, CATEGORY_TYPES.TREE)"
                ></VariantChangeItemDialog>
              </div>
            </template>
            <template #item.buttons="{item}">
              <v-icon
                  @click="removeItem(item.id)"
                  title="Удалить из выборки"
                  :disabled="applying"
              >mdi-close
              </v-icon>
            </template>
            <template #item.merge_method="{item}">
              <v-select
                  dense
                  v-model="item.merge_method"
                  :items="mergeMethods"
                  :disabled="applying"
              >
                <template #selection="{item}">{{ item.name }}</template>
                <template #item="{item}">{{ item.name }}</template>
              </v-select>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              outlined
              color="green"
              :disabled="loading || items.length === 0 || applying"
              class="mr-3"
              @click="apply"
          >
            Применить
          </v-btn>
          <v-btn
              outlined
              color="grey"
              @click="show = false"
              v-if="!applying"
          >Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import * as catalogMergeMethods from '@/common/catalogMergeMethods'
import api from "@/services/distributorCategoriesApi";
import {mapActions, mapGetters} from 'vuex';
import store from '@/store'
import VariantChangeItemDialog from "@/views/aggregator/steps/bind-catalogs/VariantChangeItemDialog";
import {CATEGORY_TYPES} from '@/views/aggregator/steps/bind-catalogs/VariantChangeItemDialog'

export default {
  name: 'VariantApprovingDialog',
  components: {VariantChangeItemDialog},
  props: [
    // Идентификатор категории поставщика, дочерние элементы которой пытаемся сопоставить с номенклатурой
    // на основе привязки указанной категории с номенклатурой
    'forCategory'
  ],
  data: () => ({
    CATEGORY_TYPES,
    show: false,
    applying: false,
    catalogMergeMethods,
    loading: true,
    headers: [
      {
        text: 'Категория поставщика',
        value: 'distributor'
      },
      {
        text: 'Папка номенклатуры',
        value: 'structure'
      },
      {
        text: 'Ветвь ассортимента',
        value: 'category'
      },
      {
        text: 'Метод слияния',
        value: 'merge_method',
        width: '180px'
      },
      {
        text: '',
        value: 'buttons',
        width: '1%'
      }
    ],
    items: []
  }),
  watch: {
    show(current, last) {
      if (current) {
        this.loadSuggestions()
        this.loading = true
      } else {
        this.applying = false
        this.items = []
      }
    }
  },
  methods: {
    ...mapActions('distributor_categories', ['actionFetchAllDistributorCategories']),

    choiseCustomVariant(e, item, type) {
      console.log(item)

      let suggestions = item.suggestions[
        {
          'TREE': 'categories',
          'CATEGORY': 'structures'
        }[type]
      ]

      if (suggestions.indexOf(e) === -1)
          suggestions.push(e)

      item[{
          'TREE': 'selected_category',
          'CATEGORY': 'selected_structure'
      }[type]] = e
    },

    loadSuggestions() {
      api
          .suggestStockCategoriesForDistributorCategory(this.forCategory.id)
          .then(result => {
            this.items = result.map(i => {
              i.merge_method = catalogMergeMethods.inherit
              i.selected_structure = i.suggestions.structures[0]

              if (i.suggestions.categories.length > 0) {
                i.selected_category = i.suggestions.categories[0]
              } else {
                i.selected_category = this.forCategory.bind_category
              }

              return i
            })

            this.loading = false
          })
    },
    removeItem(id) {
      this.items = this.items.filter(i => i.id !== id)
    },
    apply() {
      this.applying = true

      let rules = this.items.map(item => {
        return {
          "id": item.id,
          "merge_method": item.merge_method,
          "bind_category": item.selected_category,
          "bind_structure": item.selected_structure
        }
      })

      api
          .massApplyMergeBindings(rules)
          .then(() => {
            store.dispatch('actionAddInfoMessage', 'Изменения применены', {root: true})
            this.actionFetchAllDistributorCategories(this.activeDistributor).then(() => {
              this.show = false
            })
          })
          .catch((e) => {
            this.applying = false
            this.loading = false
            store.dispatch('actionAddErrorMessage', 'Ошибка применения изменений', {root: true})
          })
    }
  },
  computed: {
    ...mapGetters('distributor_categories', ['getDistributorCategoryById']),
    ...mapGetters('stock_tree', ['getStockTreeById']),
    ...mapGetters('stock_categories', ['getStockCategoryById', 'getStockRootCategories', 'getStockCategoriesSelected']),
    ...mapGetters('app_aggregator', ['activeDistributor']),

    mergeMethods() {
      return [
        {
          value: catalogMergeMethods.recursive,
          name: 'Рекурсивное'
        },
        {
          value: catalogMergeMethods.as_single,
          name: 'Слияние в одну',
        },
        {
          value: catalogMergeMethods.inherit,
          name: 'Наследовать'
        }
      ]
    }
  },
}
</script>

<style scoped></style>

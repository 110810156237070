<template>
  <v-row>
    <v-col cols="12" md="9" lg="10">
      <v-select
        v-model="changedManagers"
        label="Менеджеры"
        :items="managers"
        :disabled="item.loading"
        hide-details
        chips
        multiple
        dense
        required
      ></v-select>
    </v-col>
    <v-col cols="12" md="3" lg="2">
      <v-btn @click.stop="save" :disabled="!hasChanges" color="success" block>
        Сохранить
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    managers: Array
  },
  data: () => ({
    defaultManagers: [],
    changedManagers: []
  }),
  mounted() {
    this.defaultManagers = [...(this.item.managers || [])]
    this.changedManagers = [...this.defaultManagers]
  },
  computed: {
    hasChanges: function() {
      return !_.isEqual(
        _.sortBy(this.defaultManagers),
        _.sortBy(this.changedManagers)
      )
    }
  },
  methods: {
    ...mapActions('distributor', ['actionUpdateDistributorManagers']),
    save: function() {
      this.actionUpdateDistributorManagers({
        id: this.item.id,
        managers: this.changedManagers
      }).then(() => {
        this.defaultManagers = [...this.changedManagers]
      })
    }
  }
}
</script>

<template>
  <v-dialog :value="show" :width="dialogWidth" persistent>
    <v-card>
      <v-card-title>Привязка значений параметров</v-card-title>
      <v-card-text>
        <v-overlay
          v-if="fetchRequestInProgress"
          color="white"
          :value="true"
          absolute
        >
          <v-progress-circular
            :size="36"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-row v-else justify="space-between">
          <v-col cols="6">
            <div class="pb-3">
              <div class="subtitle-2">Выбранный элемент:</div>
              <v-simple-table v-if="selectedDistributorValue">
                <template #default>
                  <tbody>
                    <tr>
                      <td>{{ selectedDistributorValue.value }}</td>
                      <td>
                        {{ selectedDistributorValue.bind_value || '' }}
                      </td>
                      <td width="125px">
                        <v-btn
                          @click.stop="
                            selectDistributorValue(selectedDistributorValue.id)
                          "
                          color="success"
                          x-small
                          depressed
                        >
                          Выбрано
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="py-3">Ничего не выбрано</div>
            </div>

            <!-- Таблица значений поставщика -->
            <div class="subtitle-2">Значения поставщика:</div>
            <div class="mt-4 mb-2">
              <v-btn-toggle
                v-model="distributorFilter"
                class="d-flex flex-column flex-md-row"
                mandatory
                borderless
                tile
              >
                <v-btn
                  :value="distributorFilters.unbinded"
                  class="flex-grow-1"
                  color="grey darken-3"
                  outlined
                  dark
                  small
                >
                  Непривязанные ({{ unbindedDistributorValues.length }})
                </v-btn>
                <v-btn
                  :value="distributorFilters.binded"
                  class="flex-grow-1"
                  color="success"
                  outlined
                  small
                >
                  Привязанные ({{ bindedDistributorValues.length }})
                </v-btn>
                <v-btn
                  :value="distributorFilters.all"
                  class="flex-grow-1"
                  color="grey darken-3"
                  outlined
                  dark
                  small
                >
                  Все ({{ distributorValues.length }})
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-scroll-container height="46vh">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr v-for="item in tableDistributorValues" :key="item.id">
                      <td>{{ item.value }}</td>
                      <td>
                        {{ item.bind_value !== null ? item.bind_value : '' }}
                      </td>
                      <td width="110px">
                        <v-btn
                          @click.stop="selectDistributorValue(item.id)"
                          :color="item.is_selected ? 'success' : 'primary'"
                          x-small
                          depressed
                        >
                          {{ item.is_selected ? 'Выбрано' : 'Выбрать' }}
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-scroll-container>
          </v-col>
          <v-col cols="6">
            <!-- Таблица значений стока -->
            <div class="subtitle-2">Значения 1с:</div>
            <v-text-field
              v-model="stockFilter"
              :disabled="selectedDistributorValue == null"
              class="my-2"
              label="Фильтр по строке"
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              outlined
              dense
            ></v-text-field>
            <div>
              <span class="subtitle-2">Фильтровать по строке:&nbsp;</span>
              <span class="body-2">
                <a
                  v-if="textForFilter"
                  @click.prevent="() => (stockFilter = textForFilter)"
                  class="dashed"
                >
                  {{ textForFilter }}
                </a>
                <span v-else>-</span>
              </span>
            </div>
            <v-scroll-container height="55vh">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr v-for="item in tableStockValues" :key="item.id">
                      <td>{{ item.value }}</td>
                      <td width="130px">
                        <v-btn
                          @click.stop="bind(item.id)"
                          :disabled="selectedDistributorValue === null"
                          :color="item.is_binded ? 'success' : 'primary'"
                          x-small
                          depressed
                        >
                          {{ item.is_binded ? 'Отвязать' : 'Привязать' }}
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-scroll-container>
          </v-col>
          <v-overlay color="white" :value="bindRequestInProgress" absolute>
            <v-progress-circular
              :size="36"
              color="indigo lighten-4"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" color="grey darken-1" outlined>
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import distributorFeaturesApi from '@/services/distributorFeaturesApi'
import stockFeaturesApi from '@/services/stockFeaturesApi'
import {mapActions} from 'vuex'

const distributorFilters = {
  unbinded: 'unbinded',
  binded: 'binded',
  all: 'all'
}

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    distributorFeatureId: {
      default: null
    },
    stockFeatureId: {
      default: null
    }
  },
  data: () => ({
    fetchRequestInProgress: false,
    bindRequestInProgress: false,
    distributorValues: [],
    stockValues: [],
    stockFilter: '',
    selectedDistributorValueId: null,
    distributorFilter: distributorFilters.unbinded
  }),
  created() {
    this.distributorFilters = distributorFilters
  },
  watch: {
    show: function(newValue, oldValue) {
      if (newValue && !oldValue) {
        if (this.distributorFeatureId && this.stockFeatureId) {
          this.fetchRequestInProgress = true
          Promise.all([
            distributorFeaturesApi.getFeatureValues(this.distributorFeatureId),
            stockFeaturesApi.getStockFeatureValues(this.stockFeatureId)
          ])
            .then(([distributorValues, stockValues]) => {
              this.distributorValues = distributorValues
              this.stockValues = stockValues
            })
            .finally(() => {
              this.fetchRequestInProgress = false
            })
        }
      }
    },
    selectedDistributorValue: function(value) {
      if (!value) {
        this.stockFilter = ''
      }
    }
  },
  computed: {
    dialogWidth: function() {
      return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg
        ? '1280px'
        : 'auto'
    },
    selectedDistributorValue: function() {
      const selectedItem = this.distributorValues.find(
        item => this.selectedDistributorValueId === item.id
      )
      if (selectedItem) {
        const bindedItem =
          selectedItem && selectedItem.bind
            ? this.stockValues.find(({ id }) => id === selectedItem.bind)
            : null
        return { ...selectedItem, bind_value: bindedItem && bindedItem.value }
      }
      return null
    },
    bindedDistributorValues: function() {
      return this.distributorValues.filter(item => item.bind !== null)
    },
    unbindedDistributorValues: function() {
      return this.distributorValues.filter(item => item.bind === null)
    },
    tableDistributorValues: function() {
      let filteredValues = this.distributorValues
      switch (this.distributorFilter) {
        case distributorFilters.binded:
          filteredValues = this.bindedDistributorValues
          break
        case distributorFilters.unbinded:
          filteredValues = this.unbindedDistributorValues
          break
      }
      const values = filteredValues.map(item => {
        const bindedItem = item.bind
          ? this.stockValues.find(({ id }) => id === item.bind)
          : null
        return {
          ...item,
          bind_value: bindedItem ? bindedItem.value : null,
          is_selected: this.selectedDistributorValueId === item.id
        }
      })
      return _.sortBy(values, ['value'])
    },
    tableStockValues: function() {
      const filterValue = this.stockFilter
        ? this.stockFilter.trim().toLowerCase()
        : ''
      const stockValues = filterValue.length
        ? this.stockValues.filter(({ value }) =>
            value.toLowerCase().includes(filterValue)
          )
        : this.stockValues
      return stockValues.map(item => {
        return {
          ...item,
          is_binded:
            this.selectedDistributorValue &&
            this.selectedDistributorValue.bind === item.id
        }
      })
    },
    textForFilter: function() {
      return this.selectedDistributorValue
        ? this.selectedDistributorValue.bind_value ||
            this.selectedDistributorValue.value
        : ''
    }
  },
  methods: {
    ...mapActions('app_aggregator', ['actionRefreshDistributorUnbindedCounters']),
    ...mapActions('distributor_features', ['actionUpdateFeatureById']),

    selectDistributorValue: function(distributorValueId) {
      this.selectedDistributorValueId =
        this.selectedDistributorValueId !== distributorValueId
          ? distributorValueId
          : null
    },
    bind: function(stockValueId) {
      this.bindRequestInProgress = true

      // Отвязываем при повторном клике по кнопке
      const bindValueId =
        this.selectedDistributorValue.bind === stockValueId
          ? null
          : stockValueId

      distributorFeaturesApi
        .bindDistributorFeatureValue({
          id: this.selectedDistributorValueId,
          bind: bindValueId
        })
        .then(dataItem => {
          if (dataItem && dataItem.id) {
            this.distributorValues = this.distributorValues.map(item => {
              return item.id === dataItem.id ? dataItem : item
            })

            this.actionUpdateFeatureById(dataItem.feature)
          }
        })
        .finally(() => {
          this.actionRefreshDistributorUnbindedCounters().finally(() => {
            this.bindRequestInProgress = false
          })
        })
    },
    close: function() {
      this.distributorValues = []
      this.stockValues = []
      this.selectedDistributorValueId = null
      this.stockFilter = ''
      this.distributorFilter = distributorFilters.unbinded
      this.$emit('close')
    }
  }
}
</script>

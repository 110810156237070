<template>
  <v-card :loading="loading">
    <!-- Шапка -->
    <v-toolbar class="indigo lighten-2 elevation-0">
      <slot name="toolbar-left"></slot>
      <v-spacer></v-spacer>
      <slot name="toolbar-right"></slot>
      <v-btn icon dark @click="showHelp = !showHelp">
        <v-icon>mdi-help</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <!-- Помощь -->
      <v-alert
        v-if="showHelp"
        color="blue-grey lighten-5 body-2"
        class="mb-3"
        icon="mdi-school"
        dense
        prominent
        tile
      >
        <slot name="help-message"></slot>
      </v-alert>

      <!-- Контент -->
      <slot name="content">
        <v-row justify="space-between">
          <v-col cols="6">
            <div class="content-left">
              <slot name="content-left"></slot>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex">
            <div class="d-flex flex-grow-1 content-right">
              <slot name="content-right"></slot>
            </div>
          </v-col>
        </v-row>
      </slot>
    </v-card-text>

    <slot name="actions-before"></slot>

    <!-- Действия -->
    <slot name="actions">
      <v-card-actions>
        <slot name="actions-left"></slot>
        <v-spacer></v-spacer>
        <slot name="actions-right"></slot>
      </v-card-actions>
    </slot>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
  data: () => ({
    showHelp: false
  })
}
</script>

<style scoped lang="scss">
.content-left,
.content-right {
  max-width: 100%;
}
</style>

<template>
  <v-scroll-y-transition mode="out-in">
    <!-- Получение кандидатов на привязку -->
    <div
        v-if="getDistributorCategoriesBindingsFetching"
        key="bindins_fetching"
        class="flex-grow-1 settings-bind-info"
    >
      <v-overlay color="white" :value="true" absolute>
        <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Создание новой категории -->
    <div
        v-else-if="distributorCategory && creation.opened"
        key="creation"
        class="flex-grow-1"
    >
      <StockCatalogsCreation @close="creationClose" @submit="creationSubmit"/>
    </div>

    <!-- Выбор привязки папки вручную -->
    <div
        v-else-if="
        distributorCategory &&
          stockCatalogs.type == catalogTypes.structure &&
          stockCatalogs.opened
      "
        key="stock-catalogs-select-category"
        class="width-100"
    >
      <StockCatalogs
          title="Выбор папки номенклатуры"
          :type="catalogTypes.structure"
          :items="getStockRootCategories"
          :itemSelectable="() => true"
          :selectedId="stockCatalogs[catalogTypes.structure].selectedId"
          :activeItems="stockCatalogsActiveItems"
          :openItems="stockCatalogsOpenItems"
          :creationOpen="creationOpen(catalogTypes.structure)"
          @close="stockCatalogsClose"
          @confirm="stockCatalogsConfirm"
      />
    </div>

    <!-- Выбор привязки ветки ассортимента вручную -->
    <div
        v-else-if="
        distributorCategory &&
          stockCatalogs.type == catalogTypes.category &&
          stockCatalogs.opened
      "
        key="stock-catalogs-select-tree"
        class="width-100"
    >
      <StockCatalogs
          title="Выбор ветки ассортимента"
          :type="catalogTypes.category"
          :items="getStockRootTree"
          :itemSelectable="item => !item.is_group"
          :selectedId="stockCatalogs[catalogTypes.category].selectedId"
          :activeItems="stockCatalogsActiveItems"
          :openItems="stockCatalogsOpenItems"
          @close="stockCatalogsClose"
          @confirm="stockCatalogsConfirm"
      />
    </div>

    <!-- Выбор направления -->
    <div
        v-else-if="
        distributorCategory &&
          stockCatalogs.type == catalogTypes.direction &&
          stockCatalogs.opened
      "
        key="stock-catalogs-select-direction"
        class="width-100"
    >
      <StockCatalogs
          title="Выбор направления"
          :type="catalogTypes.direction"
          :items="getStockDirectionsTreeRoot"
          :itemSelectable="item => item.selectable"
          :selectedId="stockCatalogs[catalogTypes.direction].selectedId"
          :activeItems="stockCatalogsActiveItems"
          :openItems="stockCatalogsOpenItems"
          @close="stockCatalogsClose"
          @confirm="stockCatalogsConfirm"
      />
    </div>

    <!-- Информация о привязке -->
    <div
        v-else-if="distributorCategory"
        :key="distributorCategory.id"
        class="width-100 settings-bind-info"
    >
      <div style="position: sticky; top: 100px;">
        <div>
          <div class="subtitle-1 font-weight-bold">
            Как обрабатывать дочерние несвязанные категории и товары?
          </div>
          <v-radio-group v-model="changedData.merge_method" class="mt-0">
            <v-radio :value="catalogMergeMethods.ignore">
              <template #label>
                <div>
                  Не обрабатывать
                  <span class="caption">(Эта папка будет проигнорирована)</span>
                </div>
              </template>
            </v-radio>
            <v-radio v-if="testatorItem" :value="catalogMergeMethods.inherit">
              <template #label>
                <div>
                  Согласно настройкам родителя
                  <span class="caption">
                  (
                  <span class="font-weight-bold">
                    {{ testatorItemMethodName }}
                  </span>
                  . Направление и ветка ассортимента будут унаследованы.)
                </span>
                </div>
              </template>
            </v-radio>
            <v-radio :value="catalogMergeMethods.recursive">
              <template #label>
                <div>
                  Создать
                  <span class="caption">
                  (Создавать дочерние папки как у поставщика)
                </span>
                </div>
              </template>
            </v-radio>
            <v-radio :value="catalogMergeMethods.as_single">
              <template #label>
                <div>
                  Объединить
                  <span class="caption">
                  (Создавать дочерние товары в этой директории)
                </span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>

        <!-- Привязанная папка номенклатуры -->
        <div v-if="structureBindDisplay">
          <v-divider class="mt-4 mb-8"></v-divider>
          <div class="mb-3">
            <div class="subtitle-1 font-weight-bold">Папка номенклатуры:</div>
            <span class="body-2 font-weight-light">
            {{ structureBindHelpText }}
          </span>
            <v-row v-if="bindedCategory" align="center">
              <v-col cols="12" md="8">
                <v-chip
                    class="d-block text-center"
                    @click:close="
                  stockCatalogsShowInStructure(
                    catalogTypes.structure,
                    bindedCategory
                  )
                "
                    close
                    close-icon="mdi-magnify"
                    outlined
                >
                <span class="text-truncate">
                  {{ bindedCategory && bindedCategory.name }}
                </span>
                </v-chip>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                    @click="unbindCategoryButton()"
                    color="error"
                    outlined
                    block
                    small
                >
                  Отвязать
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="font-italic py-4 empty-message">
                Нет привязки
              </v-col>
            </v-row>
          </div>
          <!-- Возможные элементы привязки папок -->
          <div class="subtitle-1">Возможные варианты папок номенклатуры:</div>
          <v-chip-group
              v-if="categorySuggestItems.length"
              v-model="changedData.bind_structure"
              class="mb-2"
              active-class="success success--text"
              column
          >
            <v-chip
                v-for="item in categorySuggestItems"
                :key="item.id"
                :value="item.id"
                @click:close="
              stockCatalogsShowInStructure(catalogTypes.structure, item)
            "
                close
                close-icon="mdi-magnify"
                outlined
            >
              <span class="text-truncate">{{ item.name }}</span>
            </v-chip>
          </v-chip-group>
          <div v-else class="mt-2 mb-3 font-italic">
            Нет подходящих элементов
          </div>
          <div class="d-flex justify-end flex-column flex-md-row">
            <v-btn
                @click="stockCatalogsOpen(catalogTypes.structure)"
                color="indigo lighten-2"
                dark
                small
                depressed
            >
              Выбрать вручную
            </v-btn>
          </div>
        </div>

        <div v-if="categoryAndDirectionBindDisplay">
          <v-divider class="my-8"></v-divider>
          <!-- Привязанная ветка ассортимента -->
          <div class="mb-3">
            <div class="subtitle-1 font-weight-bold">
              Ветка ассортимента:
            </div>
            <v-row v-if="bindedTree" align="center">
              <v-col cols="12" md="8">
                <v-chip
                    class="d-block text-center"
                    @click:close="
                  stockCatalogsShowInStructure(
                    catalogTypes.category,
                    bindedTree
                  )
                "
                    close
                    close-icon="mdi-magnify"
                    outlined
                >
                <span class="text-truncate">
                  {{ bindedTree && bindedTree.name }}
                </span>
                </v-chip>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                    @click="unbindTreeButton()"
                    color="error"
                    outlined
                    block
                    small
                >
                  Отвязать
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="font-italic py-4 empty-message">
                Нет привязки
              </v-col>
            </v-row>
          </div>

          <!-- Возможные элементы привязки ветки ассортимента -->
          <div>
            <div class="subtitle-1">
              Возможные варианты веток ассортимента:
            </div>
            <v-chip-group
                v-if="treeSuggestItems.length"
                v-model="changedData.bind_category"
                class="mb-2"
                active-class="success success--text"
                column
            >
              <v-chip
                  v-for="item in treeSuggestItems"
                  :key="item.id"
                  :value="item.id"
                  @click:close="
                stockCatalogsShowInStructure(catalogTypes.category, item)
              "
                  close
                  close-icon="mdi-magnify"
                  outlined
              >
                <span class="text-truncate">{{ item.name }}</span>
              </v-chip>
            </v-chip-group>
            <div v-else class="mt-2 mb-3 font-italic">
              Нет подходящих элементов
            </div>
            <div class="d-flex justify-end flex-column flex-md-row">
              <v-btn
                  @click="stockCatalogsOpen(catalogTypes.category)"
                  color="indigo lighten-2"
                  dark
                  small
                  depressed
              >
                Выбрать вручную
              </v-btn>
            </div>
          </div>

          <v-divider class="my-8"></v-divider>

          <!-- Направление -->
          <div class="mb-3">
            <div class="subtitle-1 font-weight-bold">Товарное направление:</div>
            <v-row v-if="bindedDirection" align="center">
              <v-col cols="12" md="8">
                <v-chip
                    class="d-block text-center"
                    @click:close="
                  stockCatalogsShowInStructure(
                    catalogTypes.direction,
                    bindedDirection
                  )
                "
                    close
                    close-icon="mdi-magnify"
                    outlined
                >
                <span class="text-truncate">
                  {{ bindedDirection && bindedDirection.name }}
                </span>
                </v-chip>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                    @click="unbindDirectionButton()"
                    color="error"
                    outlined
                    block
                    small
                >
                  Отвязать
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="font-italic py-4 empty-message">
                Нет привязки
              </v-col>
            </v-row>
          </div>

          <!-- Возможные элементы привязки направлений -->
          <div>
            <div class="subtitle-1">Возможные варианты направлений:</div>
            <v-chip-group
                v-if="directionSuggestItems.length"
                v-model="changedData.direction"
                class="mb-2"
                active-class="success success--text"
                column
            >
              <v-chip
                  v-for="item in directionSuggestItems"
                  :key="item.id"
                  :value="item.id"
                  @click:close="
                stockCatalogsShowInStructure(catalogTypes.direction, item)
              "
                  close
                  close-icon="mdi-magnify"
                  outlined
              >
                <span class="text-truncate">{{ item.name }}</span>
              </v-chip>
            </v-chip-group>
            <div v-else class="mt-2 mb-3 font-italic">
              Нет подходящих элементов
            </div>
            <div class="d-flex justify-end flex-column flex-md-row">
              <v-btn
                  @click="stockCatalogsOpen(catalogTypes.direction)"
                  color="indigo lighten-2"
                  dark
                  small
                  depressed
              >
                Выбрать вручную
              </v-btn>
            </div>
          </div>
        </div>

        <!-- Кнопки сохранения -->
        <v-divider class="mt-8"></v-divider>
        <div>
          <!--      <v-sticky-actions>-->
          <div class="py-4">
            <v-alert
                v-model="alert"
                type="error"
                class="flex-grow-1"
                dense
                dismissible
            >
              Все настройки обязательны
            </v-alert>
            <div class="d-flex justify-end flex-column flex-md-row">
              <v-btn
                  @click="resetChanges"
                  :disabled="!hasChanges"
                  class="mr-md-2"
                  color="error"
                  small
                  depressed
              >
                Сбросить
              </v-btn>
              <v-btn
                  @click="saveChanges"
                  :disabled="!saveButtonEnabled"
                  class="mt-1 mt-md-0"
                  color="primary"
                  small
                  depressed
              >
                Сохранить изменения
              </v-btn>
            </div>
          </div>
        </div>
        <!--      </v-sticky-actions>-->
        <!-- Оверлей при обновлении -->
        <v-overlay
            color="white"
            :value="getDistributorCategoriesUpdating"
            z-index="4"
            absolute
        >
          <v-progress-circular
              :size="50"
              color="indigo lighten-4"
              indeterminate
          ></v-progress-circular>
        </v-overlay>
      </div>

    </div>

    <!-- Лоадер -->
    <div v-else class="flex-grow-1 text-center py-12">
      <div v-if="isLoading">
        <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="title font-weight-light">
        Выберите категорию
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapActions} from 'vuex'
import {scrollToTargetMixin} from '@/common/scroll/mixins'
import * as catalogMergeMethods from '@/common/catalogMergeMethods'
import * as catalogTypes from '@/common/catalogTypes'
import StockCatalogs from './StockCatalogs'
import StockCatalogsCreation from './StockCatalogsCreation'

const settingsInitData = {
  merge_method: catalogMergeMethods.ignore,
  bind_structure: null,
  bind_category: null,
  direction: null
}

const stockCatalogsInitData = {
  // тип каталога
  type: null,
  // Отображение ручного выбора
  opened: false,
  // id элемента для показа в дереве
  showInStructureId: null,
  // id выбранного элемента
  [catalogTypes.structure]: {selectedId: null},
  [catalogTypes.category]: {selectedId: null},
  [catalogTypes.direction]: {selectedId: null}
}

const creationInitData = {
  opened: false,
  parentItem: null,
  type: null
}

export default {
  mixins: [scrollToTargetMixin],
  components: {
    StockCatalogs,
    StockCatalogsCreation
  },
  props: {
    distributorCategory: Object
  },
  created() {
    this.catalogMergeMethods = catalogMergeMethods
    this.catalogTypes = catalogTypes
  },
  data: () => ({
    // Id выбранной категории
    distributorCategoryId: null,
    // Настройки выбранной категории
    defaultData: {...settingsInitData},
    // Изменения настроек
    changedData: {...settingsInitData},
    // Данные для ручного выбора
    stockCatalogs: _.cloneDeep(stockCatalogsInitData),
    // отображение формы создания папок в каталогах
    creation: {...creationInitData},
    alert: false
  }),
  watch: {
    // Устанавливаем значения при смене категории
    distributorCategory: function (distributorCategory) {
      if (
          !distributorCategory ||
          distributorCategory.id !== this.distributorCategoryId
      ) {
        this.stockCatalogs = _.cloneDeep(stockCatalogsInitData)
        this.creation = {...this.creation, ...creationInitData}
      }
      if (distributorCategory) {
        const settingsData = {
          merge_method: distributorCategory.merge_method,
          bind_structure: distributorCategory.bind_structure,
          bind_category: distributorCategory.bind_category,
          direction: distributorCategory.direction
        }
        this.defaultData = {...this.defaultData, ...settingsData}
        if (distributorCategory.id !== this.distributorCategoryId) {
          this.changedData = {...this.changedData, ...settingsData}
        }
      } else {
        this.defaultData = {...this.defaultData, ...settingsInitData}
        this.changedData = {...this.changedData, ...settingsInitData}
      }
      this.alert = false
      this.distributorCategoryId = distributorCategory
          ? distributorCategory.id
          : null
    }
  },
  computed: {
    ...mapGetters('distributor_categories', [
      'getDistributorCategoriesUpdating',
      'getDistributorCategoriesBindingsFetching',
      'getDistributorCategoryTestator',
      'getDistributorCategorySuggest'
    ]),
    ...mapGetters('stock_categories', [
      'getStockCategoriesFetching',
      'getStockCategories',
      'getStockCategoryById',
      'getStockCategoriesByIdList',
      'getStockCategoryAncestorsById',
      'getStockRootCategories'
    ]),
    ...mapGetters('stock_tree', [
      'getStockTreeFetching',
      'getStockTreeById',
      'getStockTreeByIdList',
      'getStockTreeAncestorsById',
      'getStockRootTree'
    ]),
    ...mapGetters('stock_directions', [
      'getStockDirectionsTreeRoot',
      'getStockDirectionsById',
      'getStockDirectionsByIdList',
      'getStockDirectionsAncestorsById'
    ]),
    // Настройки с учетом изменений
    settings: function () {
      return {
        ...this.defaultData,
        ...this.changedData
      }
    },
    // Наличие изменений
    hasChanges: function () {
      return !_.isEqual(this.defaultData, this.changedData)
    },
    isValid: function () {
      const {
        merge_method,
        bind_structure,
        bind_category,
        direction
      } = this.settings
      // Если указан тип обработки необходимо указать все настройки
      return ![
        catalogMergeMethods.inherit,
        catalogMergeMethods.ignore
      ].includes(merge_method)
          ? bind_structure && bind_category && direction
          : true
    },
    // Отображение настроек привязки номенклатуры
    structureBindDisplay: function () {
      if (this.changedData.merge_method !== catalogMergeMethods.ignore) {
        return (
            this.changedData.merge_method === catalogMergeMethods.recursive ||
            this.changedData.merge_method === catalogMergeMethods.as_single ||
            (this.changedData.merge_method === catalogMergeMethods.inherit &&
                this.testatorItem &&
                this.testatorItem.merge_method === catalogMergeMethods.recursive)
        )
      }
      return false
    },
    structureBindHelpText: function () {
      if (this.changedData.merge_method === catalogMergeMethods.recursive) {
        return 'Укажите папку номенклатуры, в которой нужно создавать вложенные папки и товары'
      }
      if (this.changedData.merge_method === catalogMergeMethods.as_single) {
        return 'Укажите папку номенклатуры, в которой нужно создавать все вложенные товары'
      }
      return 'Укажите папку номенклатуры, если она уже существует'
    },
    categoryAndDirectionBindDisplay: function () {
      return (
          this.changedData.merge_method === catalogMergeMethods.recursive ||
          this.changedData.merge_method === catalogMergeMethods.as_single
      )
    },
    // Активность кнопки сохранения
    saveButtonEnabled: function () {
      return this.hasChanges && this.isValid
    },
    // Привязанное направление
    bindedDirection: function () {
      return this.settings.direction
          ? this.getStockDirectionsById(this.settings.direction)
          : null
    },
    // Привязанный бренд
    bindedCategory: function () {
      return this.settings.bind_structure
          ? this.getStockCategoryById(this.settings.bind_structure)
          : null
    },
    // Привязанная ветка ассортимента
    bindedTree: function () {
      return this.settings.bind_category
          ? this.getStockTreeById(this.settings.bind_category)
          : null
    },
    // Получение наследодателя выбранного элемента
    testatorItem: function () {
      return this.distributorCategory && this.distributorCategory.parentItem
          ? this.getDistributorCategoryTestator(
              this.distributorCategory.parentItem
          )
          : null
    },
    testatorItemMethodName: function () {
      if (this.testatorItem) {
        switch (this.testatorItem.merge_method) {
          case catalogMergeMethods.recursive:
            return 'Создать'
          case catalogMergeMethods.as_single:
            return 'Объединить'
        }
      }
      return ''
    },
    // Список подходящих направлений
    directionSuggestItems: function () {
      const idList = []
      if (this.testatorItem && this.testatorItem.direction) {
        idList.push(this.testatorItem.direction)
      }
      return this.getStockDirectionsByIdList(_.uniq(idList))
    },
    suggestItems: function () {
      return this.getDistributorCategorySuggest(this.distributorCategory.id)
    },
    // Получаем список подходящих брендов для привязки к категориям
    categorySuggestItems: function () {
      const idList = [
        ...((this.suggestItems && this.suggestItems.category) || [])
      ]
      if (this.defaultData.bind_structure) {
        idList.push(this.defaultData.bind_structure)
      }
      if (this.testatorItem && this.testatorItem.bind_structure) {
        idList.push(this.testatorItem.bind_structure)
      }
      return this.getStockCategoriesByIdList(_.uniq(idList))
    },
    // Получаем список подходящих брендов для привязки к ассортименту
    treeSuggestItems: function () {
      const idList = [...((this.suggestItems && this.suggestItems.tree) || [])]
      if (this.defaultData.bind_category) {
        idList.push(this.defaultData.bind_category)
      }
      if (this.testatorItem && this.testatorItem.bind_category) {
        idList.push(this.testatorItem.bind_category)
      }
      return this.getStockTreeByIdList(_.uniq(idList))
    },
    // Список id для автоматического раскрытия дерева
    stockCatalogsOpenItems: function () {
      // берем id элемента, который нужно отобразить в структуре или id выбранного элемента
      if (this.stockCatalogs.type) {
        let itemId =
            this.stockCatalogs.showInStructureId ||
            this.stockCatalogs[this.stockCatalogs.type].selectedId
        if (itemId) {
          switch (this.stockCatalogs.type) {
            case catalogTypes.structure:
              return this.getStockCategoryAncestorsById(itemId).map(
                  item => item.id
              )
            case catalogTypes.category:
              return this.getStockTreeAncestorsById(itemId).map(item => item.id)
            case catalogTypes.direction:
              return this.getStockDirectionsAncestorsById(itemId).map(
                  item => item.id
              )
          }
        }
      }
      return []
    },
    // Список id подсвеченных элементов в структуре
    stockCatalogsActiveItems: function () {
      const {showInStructureId} = this.stockCatalogs
      return showInStructureId ? [showInStructureId] : []
    },
    isLoading: function () {
      return this.getStockTreeFetching || this.getStockCategoriesFetching
    }
  },
  methods: {
    ...mapActions('distributor_categories', [
      'actionBindDistributorCategories'
    ]),
    ...mapActions('stock_categories', ['actionAddStockCategory']),
    ...mapActions('stock_tree', ['actionAddStockTree']),
    // Открыть ручной выбор
    stockCatalogsOpen: function (type) {
      this.stockCatalogs.type = type
      this.stockCatalogs.opened = true
    },
    // Закрыть ручной выбор
    stockCatalogsClose: function () {
      this.stockCatalogs.opened = false
      this.stockCatalogs.showInStructureId = null
    },
    // Подтверждение ручного выбора
    stockCatalogsConfirm: function (id) {
      this.stockCatalogs[this.stockCatalogs.type].selectedId = id
      switch (this.stockCatalogs.type) {
        case catalogTypes.structure:
          this.changedData.bind_structure = id
          break
        case catalogTypes.category:
          this.changedData.bind_category = id
          break
        case catalogTypes.direction:
          this.changedData.direction = id
          break
      }
      this.stockCatalogsClose()
    },
    // Показать элемент в структуре
    stockCatalogsShowInStructure: function (type, item) {
      this.stockCatalogs.showInStructureId = item.id
      this.stockCatalogsOpen(type)
      this.tryScrollToTargetId(item.id)
    },
    creationOpen: function (type) {
      return parentItem => {
        this.creation = {
          type,
          parentItem,
          opened: true
        }
      }
    },
    // Закрыть форму создания структуры
    creationClose: function () {
      this.creation = {...this.creation, ...creationInitData}
    },
    // Форма создания каталога
    creationSubmit: function ({name}) {
      const action =
          this.creation.type === catalogTypes.structure
              ? this.actionAddStockCategory
              : this.actionAddStockTree
      action({
        name,
        parent: this.creation.parentItem.id
      }).then(item => {
        // Подсветка нового элемента в дереве
        this.stockCatalogs[this.stockCatalogs.type].selectedId = item.id
        // Открываем ветку в дереве
        this.stockCatalogs.showInStructureId = item.id
        // закрываем форму добавления
        this.creationClose()
      })
    },
    unbindDirectionButton: function () {
      this.changedData.direction = null
    },
    // Кнопка отвязки категории
    unbindCategoryButton: function () {
      this.changedData.bind_structure = null
    },
    // Кнопка отвязки ветки ассортимента
    unbindTreeButton: function () {
      this.changedData.bind_category = null
    },
    // Сброс всех изменений
    resetChanges: function () {
      this.changedData = _.cloneDeep(this.defaultData)
    },
    // Сохранение изменений
    saveChanges: function () {
      const {
        merge_method,
        bind_structure,
        bind_category,
        direction
      } = this.settings
      let data = {
        id: this.distributorCategoryId,
        bind_category: null,
        direction: null,
        bind_structure: null,
        merge_method
      }
      if (
          merge_method === catalogMergeMethods.recursive ||
          merge_method === catalogMergeMethods.as_single
      ) {
        data = {
          ...data,
          direction: direction || null,
          bind_category: bind_category || null,
          bind_structure: bind_structure || null
        }
      } else if (merge_method === catalogMergeMethods.inherit) {
        data = {
          ...data,
          bind_structure: bind_structure || null
        }
      }
      this.actionBindDistributorCategories(data)
    }
  }
}
</script>

<style scoped lang="scss">
.width-100 {
  width: 100%;
}

.settings-bind-info {
  position: relative;
}
</style>

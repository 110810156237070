<template>
  <div>
    <div class="subtitle-1 font-weight-bold mb-3">{{ title }}</div>
    <!-- Поле фильтра -->
    <v-text-field
      v-model="search"
      label="Фильтрация по названию"
      flat
      hide-details
      clearable
      clear-icon="mdi-close-circle-outline"
      outlined
      dense
    ></v-text-field>

    <!-- Дерево выбора -->
    <v-treeview
      :items="items"
      :search="search"
      :open="openItems"
      :active="activeItems"
      class="py-3"
      hoverable
      open-on-click
      dense
    >
      <template #append="{ item }">
        <v-btn
          v-if="itemSelectable(item)"
          @click.stop="select(item)"
          :color="buttonColor(item)"
          :id="scrollTargetId(item.id)"
          class="mr-2"
          small
          depressed
        >
          {{ buttonText(item) }}
        </v-btn>
        <v-btn v-if="creationOpen" @click.stop="creationOpen(item)" icon>
          <v-icon color="grey">mdi-plus-circle</v-icon>
        </v-btn>
      </template>
    </v-treeview>

    <v-sticky-actions>
      <div class="d-flex justify-end flex-column flex-md-row py-4">
        <v-btn
          @click="$emit('close')"
          class="mr-2"
          color="grey darken-1"
          outlined
          small
          depressed
        >
          Назад
        </v-btn>
        <v-btn
          @click="$emit('confirm', tmpSelectedId)"
          :disabled="!tmpSelectedId"
          color="success"
          small
          depressed
        >
          Продолжить
        </v-btn>
      </div>
    </v-sticky-actions>
  </div>
</template>

<script>
import { scrollToTargetMixin } from '@/common/scroll/mixins'

export default {
  mixins: [scrollToTargetMixin],
  props: {
    title: String,
    items: Array,
    itemSelectable: Function,
    type: String,
    selectedId: Number,
    activeItems: Array,
    openItems: Array,
    creationOpen: Function
  },
  created() {
    this.tmpSelectedId = this.selectedId
  },
  data: () => ({
    // значение в поле фильтра
    search: '',
    tmpSelectedId: null
  }),
  methods: {
    select: function(item) {
      this.tmpSelectedId = this.tmpSelectedId !== item.id ? item.id : null
    },
    // Цвет кнопки
    buttonColor: function(item) {
      return this.tmpSelectedId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки
    buttonText: function(item) {
      return this.tmpSelectedId === item.id ? 'Выбрано' : 'Выбрать'
    }
  }
}
</script>

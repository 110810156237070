<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    class="thin-table"
    disable-sort
    hide-default-footer
    dense
  >
    <template #item.photos="{ item }">
      <v-popup-image class="my-2" :src-original="getPhoto(item)" />
    </template>
    <template #item.info="{ item }">
      <div>
        <b>Артикул:</b>
        {{ item.article || 'нет' }}
      </div>
      <div>
        <b>Бренд:</b>
        {{ getBrandName(item) }}
      </div>
      <div>
        <b>Название:</b>
        {{ item.name }}
      </div>
    </template>
    <template #item.select="{ item }">
      <slot name="action" :item="item"></slot>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    items: Array,
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  created() {
    this.headers = [
      {
        text: 'Фото',
        value: 'photos',
        align: 'center',
        width: '76'
      },
      {
        text: 'Информация',
        value: 'info',
        width: '100%'
      },
      {
        text: '',
        value: 'select',
        align: 'center'
      }
    ]
  },
  computed: {
    ...mapGetters('stock_brands', ['getStockBrandById'])
  },
  methods: {
    getPhoto: function(item) {
      return item.images && item.images.length ? item.images[0].url : ''
    },
    getBrandName: function(item) {
      const brand = this.getStockBrandById(item.brand)
      return brand ? brand.name : 'нет'
    }
  }
}
</script>

<template>
  <v-form ref="form" @submit.prevent="submit">
    <div class="subtitle-1">Создание нового каталога:</div>
    <v-text-field
      v-model="name"
      :rules="rules.name"
      label="Название каталога"
      class="mt-2"
      outlined
      dense
      required
    ></v-text-field>
    <div class="d-flex justify-end flex-column flex-md-row">
      <v-btn
        @click="$emit('close')"
        class="mr-md-2"
        color="grey darken-1"
        small
        depressed
        outlined
      >
        Назад
      </v-btn>
      <v-btn type="submit" class="mt-1 mt-md-0" color="primary" small depressed>
        Создать
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    // Имя новой категории
    name: '',
    // Правила валидации полей
    rules: {
      name: [v => (v || '').length > 0 || 'Введите название каталога']
    }
  }),
  methods: {
    // Отправка формы
    submit: function() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', { name: this.name })
      }
    }
  }
}
</script>

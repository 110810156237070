<template>
  <StepSkeleton :loading="cardLoading">
    <!-- Помощь -->
    <template #help-message>
      <div class="title pb-1">Список параметров поставщика</div>
      <img src="@/assets/images/help/feature_distributor.png" class="mb-2" />
      <ol class="pb-6">
        <li>Фильтр элементов по активности</li>
        <li>Фильтр элементов по привязке</li>
        <li>Виджет вкл/выкл параметра</li>
        <li>
          Иконка статуса привязки (зеленый - привязан, серый - не привязан)
        </li>
        <li>Кнопка выбора параметра для привязки</li>
      </ol>

      <div class="title pb-1">Настройки привязки</div>
      <img src="@/assets/images/help/feature_settings.png" class="mb-2" />
      <ol class="pb-6">
        <li>
          Текущий привязанный элемент. Иконка поиска открывает положение данного
          элемента в полном дереве параметров из 1с.
        </li>
        <li>
          Возможный элемент для привязки, клик для привязки. Иконка поиска
          открывает положение данного элемента в полном дереве параметров из 1с.
        </li>
        <li>Кнопка отображения полного дерева параметров из 1с.</li>
        <li>
          Изменения не сохраняются автоматически, нужно обязательно жать кнопку
          "Сохранить изменения"
        </li>
      </ol>

      <div class="title pb-1">Полное дерево параметров из 1с</div>
      <img src="@/assets/images/help/feature_tree.png" class="mb-2" />
      <ol>
        <li>Фильтр параметров по названию</li>
        <li>Параметр в 1с. Кнопка выбора элемента для привязки.</li>
        <li>
          "Назад" - возвращение к настройкам привязки. "Продолжить" -
          возвращение к настройкам привязки и установка выбранного элемента в
          качестве цели привязки.
        </li>
      </ol>
    </template>

    <!-- Левая часть -->
    <template #content-left>
      <!-- Выбранный элемент -->
      <div v-if="selectedFeature" class="pb-6">
        <div class="subtitle-1">Выбранный элемент:</div>
        <DistributorFeatureTable
          :items="selectedFeatureList"
          :selectedItem="selectedFeature"
          @select-item="onSelectFeature"
          hide-default-footer
          disable-sort
        />
        <v-divider></v-divider>
      </div>

      <!-- Фильтр -->
      <v-checkbox
        v-model="filterShowEnabled"
        :label="`Показывать активные (${countEnabled})`"
        class="mt-0"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="filterShowDisabled"
        :label="`Показывать неактивные (${countDisabled})`"
        class="mt-1"
        hide-details
      ></v-checkbox>

      <div class="mt-4 mb-2">
        <v-btn-toggle
          v-model="filter"
          class="d-flex flex-column flex-md-row"
          mandatory
          borderless
          tile
        >
          <v-btn
            :value="filters.unbinded"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Непривязанные ({{ countFilteredUnbinded }})
          </v-btn>
          <v-btn
            :value="filters.binded"
            class="flex-grow-1"
            color="success"
            outlined
            small
          >
            <v-badge dot color="red" :value="countBindedFeaturesWithUnbindedValues">
              Привязанные ({{ countFilteredBinded }})
            </v-badge>
          </v-btn>
          <v-btn
            :value="filters.all"
            class="flex-grow-1"
            color="grey darken-3"
            outlined
            dark
            small
          >
            Все ({{ countFilteredAll }})
          </v-btn>
        </v-btn-toggle>
      </div>

      <!-- Таблица параметров поставщика -->
      <DistributorFeatureTable
        :items="filteredDistributorFeatures"
        :loading="
          getDistributorFeaturesFetching || getDistributorFeaturesUpdating
        "
        :selectedItem="selectedFeature"
        @select-item="onSelectFeature"
      />
    </template>

    <!-- Правая часть -->
    <template #content-right>
      <!-- Настройки выбранного параметра -->
      <Settings :selectedFeature="selectedFeature" />
    </template>

    <template #actions>
      <v-void />
    </template>
  </StepSkeleton>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepSkeleton from '../StepSkeleton'
import DistributorFeatureTable from './DistributorFeatureTable'
import Settings from './Settings'

const filters = {
  unbinded: 'unbinded',
  binded: 'binded',
  all: 'all'
}

export default {
  components: {
    StepSkeleton,
    DistributorFeatureTable,
    Settings
  },
  created() {
    this.filters = filters
    // Загрузка параметров при переходе по табам
    this.actionFetchStockFeatures()
    if (this.activeDistributor) {
      this.actionFetchDistributorFeatures(this.activeDistributor)
    }
  },
  destroyed() {
    this.actionClearStockFeatures()
    this.actionClearDistributorFeatures()
  },
  data: () => ({
    filter: filters.unbinded,
    filterShowEnabled: true,
    filterShowDisabled: false,
    selectedFeatureId: null
  }),
  watch: {
    // Загрузка параметров при загрузке страницы и смене поставщика
    activeDistributor: function(distributorId) {
      if (distributorId) {
        this.actionFetchDistributorFeatures(distributorId)
      }
    }
  },
  computed: {
    ...mapGetters('app_aggregator', ['activeDistributor']),
    ...mapGetters('distributor_features', [
      'getDistributorFeatures',
      'getDistributorFeaturesFetching',
      'getDistributorFeaturesUpdating',
      'getDistributorFeatureById'
    ]),
    ...mapGetters('stock_features', ['getStockFeaturesAdding']),
    // Получение объекта выбранного параметра
    selectedFeature: function() {
      return this.selectedFeatureId
        ? this.getDistributorFeatureById(this.selectedFeatureId)
        : null
    },
    selectedFeatureList: function() {
      return this.selectedFeature ? [this.selectedFeature] : []
    },
    // Список параметров отфильтрованный по активности
    distributorFeaturesFilteredByEnabled: function() {
      return this.getDistributorFeatures.filter(
        item =>
          (this.filterShowEnabled && item.enabled) ||
          (this.filterShowDisabled && !item.enabled)
      )
    },
    // Список брендов отфильтрованный по привязке
    filteredDistributorFeatures: function() {
      return this.distributorFeaturesFilteredByEnabled.filter(item => {
        switch (this.filter) {
          case filters.binded:
            return item.bind !== null
          case filters.unbinded:
            return item.bind === null
        }
        return true
      })
    },
    // Количество параметров
    countFilteredAll: function() {
      return this.distributorFeaturesFilteredByEnabled.length
    },
    // Количество привязанных параметров
    countFilteredBinded: function() {
      return this.distributorFeaturesFilteredByEnabled.filter(
        item => item.bind !== null
      ).length
    },
    // Количество непривязанных параметров
    countFilteredUnbinded: function() {
      return this.distributorFeaturesFilteredByEnabled.filter(
        item => item.bind === null
      ).length
    },
    // Количество активных
    countEnabled: function() {
      return this.getDistributorFeatures.filter(item => item.enabled).length
    },
    // Количество неактивных
    countDisabled: function() {
      return this.getDistributorFeatures.length - this.countEnabled
    },
    cardLoading: function() {
      return this.getDistributorFeaturesFetching || this.getStockFeaturesAdding
    },
    // Кол-во свойств с непривязанными значениями
    countBindedFeaturesWithUnbindedValues: function() {
      return this.getDistributorFeatures.filter(item => item.enabled && item.unbinded_values > 0).length
    }
  },
  methods: {
    ...mapActions('distributor_features', [
      'actionFetchDistributorFeatures',
      'actionClearDistributorFeatures',
      'actionFetchFeatureBindings'
    ]),
    ...mapActions('stock_features', [
      'actionFetchStockFeatures',
      'actionClearStockFeatures'
    ]),
    // Событие выбора параметра
    onSelectFeature: function(item) {
      if (this.selectedFeatureId === item.id) {
        this.selectedFeatureId = null
      } else {
        if (item.binding_items) {
          this.selectedFeatureId = item.id
        } else {
          this.actionFetchFeatureBindings(item.id).then(() => {
            this.selectedFeatureId = item.id
          })
        }
      }
    }
  }
}
</script>

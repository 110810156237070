<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="itemsPerPage"
    class="thin-table"
    disable-sort
    hide-default-footer
    dense
  >
    <template #item.enabled="{ item }">
      <v-switch
        :input-value="item.enabled"
        :disabled="item.bind != null"
        @change="switchItem(item)"
      ></v-switch>
    </template>
    <template #item.photos="{ item }">
      <v-popup-image class="my-2" :src-original="getPhoto(item)" />
    </template>
    <template #item.info="{ item }">
      <div>
        <b>Артикул:</b>
        {{ item.article || 'нет' }}
      </div>
      <div>
        <b>Бренд:</b>
        {{ getBrandName(item) }}
      </div>
      <div>
        <b>Название:</b>
        {{ item.name }}
      </div>
    </template>
    <template #item.status="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon :color="linkColor(item)" v-on="on">
            mdi-link-box-variant
          </v-icon>
        </template>
        <span>{{ linkTooltipText(item) }}</span>
      </v-tooltip>
    </template>
    <template #item.select="{ item }">
      <v-btn
        :color="buttonColor(item)"
        @click="selectItem(item)"
        small
        depressed
      >
        {{ buttonText(item) }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    items: Array,
    loading: {
      type: Boolean,
      default: false
    },
    onSelect: Function,
    itemsPerPage: Number,
    selectedItem: Object
  },
  created() {
    this.headers = [
      {
        text: '',
        value: 'enabled',
        align: 'center'
      },
      {
        text: 'Фото',
        value: 'photos',
        align: 'center',
        width: '80'
      },
      {
        text: 'Информация',
        value: 'info',
        width: '100%'
      },
      {
        text: '',
        value: 'status',
        align: 'center'
      },
      {
        text: '',
        value: 'select',
        align: 'center'
      }
    ]
  },
  computed: {
    ...mapGetters('distributor_brands', ['getDistributorBrandById']),
    selectedItemId: function() {
      return this.selectedItem && this.selectedItem.id
        ? this.selectedItem.id
        : null
    }
  },
  methods: {
    ...mapActions('distributor_products', ['actionSwitchDistributorProduct']),
    ...mapActions('stock_products', [
      'actionFetchStockProductsSuggest',
      'actionFetchStockProductById',
      'actionClearStockProductsSearch'
    ]),
    getPhoto: function(item) {
      return item.images && item.images.length ? item.images[0].url : ''
    },
    getBrandName: function(item) {
      const brand = this.getDistributorBrandById(item.brand)
      return brand ? brand.name : 'нет'
    },
    // Цвет иконки привязки
    linkColor: function(item) {
      return item.bind !== null ? 'success' : 'grey darken-3'
    },
    // Текст тултипа иконки привязки
    linkTooltipText: function(item) {
      return item.bind !== null ? 'Связано' : 'Не связано'
    },
    // Цвет кнопки выбора
    buttonColor: function(item) {
      return this.selectedItemId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки выбора
    buttonText: function(item) {
      return this.selectedItemId === item.id ? 'Выбрано' : 'Выбрать'
    },
    // Клик по кнопке выбора
    selectItem: function(item) {
      const setSelectedItem = (itemId, item) => {
        this.onSelect(item)
      }
      if (this.selectedItemId === item.id) {
        setSelectedItem(null, null)
      } else {
        this.actionClearStockProductsSearch()
        Promise.all([
          this.actionFetchStockProductsSuggest(item.id),
          this.actionFetchStockProductById(item.bind)
        ]).finally(() => {
          setSelectedItem(item.id, item)
        })
      }
    },
    switchItem: function(item) {
      this.actionSwitchDistributorProduct({
        id: item.id,
        enabled: !item.enabled
      })
    }
  }
}
</script>

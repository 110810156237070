<template>
  <div>
    <!-- Поле фильтра -->
    <v-text-field
      v-model="search"
      label="Фильтрация параметров"
      flat
      hide-details
      clearable
      clear-icon="mdi-close-circle-outline"
      outlined
      dense
    ></v-text-field>

    <!-- Дерево выбора -->
    <v-treeview
      :items="getStockRootFeatures"
      :search="search"
      :open="openItems"
      :active="activeItems"
      class="py-3"
      hoverable
      open-on-click
      dense
    >
      <template #append="{ item }">
        <v-btn
          v-if="!item.children"
          @click.stop="showFeatureValues(item)"
          class="mr-2"
          small
          depressed
        >
          Значения
        </v-btn>
        <v-btn
          v-if="!item.children"
          @click="$emit('select-item', item)"
          :color="buttonColor(item)"
          :id="scrollTargetId(item.id)"
          small
          depressed
        >
          {{ buttonText(item) }}
        </v-btn>
      </template>
    </v-treeview>

    <v-sticky-actions>
      <div class="d-flex justify-end flex-column flex-md-row py-4">
        <v-btn
          @click="$emit('close')"
          class="mr-2"
          color="grey darken-1"
          outlined
          small
          depressed
        >
          Назад
        </v-btn>
        <v-btn
          @click="$emit('confirm')"
          :disabled="!selectedId"
          color="success"
          small
          depressed
        >
          Продолжить
        </v-btn>
      </div>
    </v-sticky-actions>

    <!-- Модальное окно со списком значений -->
    <v-dialog v-model="featureValues.display" width="600px" scrollable>
      <v-card>
        <v-card-title>
          <span class="subtitle-1">
            Значения параметра
          </span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template #default>
              <tbody>
                <tr v-for="(item, index) in featureValues.items" :key="index">
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-overlay
            color="white"
            :value="featureValues.requestInProgress"
            absolute
          >
            <v-progress-circular
              :size="50"
              color="indigo lighten-4"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              @click="featureValues.display = false"
              color="grey darken-1"
              outlined
            >
              Закрыть
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scrollToTargetMixin } from '@/common/scroll/mixins'
import stockFeaturesApi from '@/services/stockFeaturesApi'

export default {
  mixins: [scrollToTargetMixin],
  props: {
    selectedId: Number,
    activeItems: Array,
    openItems: Array
  },
  data: () => ({
    // значение в поле фильтра
    search: '',
    featureValues: {
      display: false,
      items: [],
      requestInProgress: false
    }
  }),
  computed: {
    ...mapGetters('stock_features', ['getStockRootFeatures'])
  },
  methods: {
    // Цвет кнопки
    buttonColor: function(item) {
      return this.selectedId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки
    buttonText: function(item) {
      return this.selectedId === item.id ? 'Выбрано' : 'Выбрать'
    },
    showFeatureValues: function(item) {
      this.featureValues = {
        ...this.featureValues,
        display: true,
        items: [],
        requestInProgress: true
      }
      stockFeaturesApi
        .getStockFeatureValues(item.id)
        .then(values => {
          this.featureValues.items = values
        })
        .finally(() => {
          this.featureValues.requestInProgress = false
        })
    }
  }
}
</script>

<template>
  <v-container>
    <div v-if="isAdmin">
      <div class="title my-3">Добавление поставщика</div>
      <!-- Форма привязки контрагента -->
      <DistributorForm :managers="managers" />
      <!-- Разделитель -->
      <v-divider class="mb-6"></v-divider>
    </div>
    <div class="title my-3">Список поставщиков</div>
    <!-- Таблица привязанных контрагентов -->
    <DistributorTable :managers="managers" :editable="isAdmin" />
  </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import permissions from '@/common/permissions'
import { DistributorForm, DistributorTable } from './index'

export default {
  components: {
    DistributorForm,
    DistributorTable
  },
  mounted() {
    if (this.isAdmin) {
      this.actionFetchUsers()
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission', 'getUsers']),
    isAdmin: function() {
      return this.getCurrentUserHasPermission(permissions.appAggregatorAdmin)
    },
    managers: function() {
      const managersPermissions = [
        permissions.appAggregator,
        permissions.appAggregatorAdmin
      ]
      return this.getUsers
        .filter(
          item =>
            _.intersection(item.permissions, managersPermissions).length > 0
        )
        .map(({ id, name, patronymic, surname }) => ({
          text: `${surname} ${name} ${patronymic}`,
          value: id
        }))
    }
  },
  methods: {
    ...mapActions('auth', ['actionFetchUsers'])
  }
}
</script>

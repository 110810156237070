<template>
  <v-scroll-y-transition mode="out-in">
    <!-- Получение кандидатов на привязку -->
    <div
      v-if="getStockProductsSuggestFetching"
      key="suggest_fetching"
      class="flex-grow-1 bind-info"
    >
      <v-overlay color="white" :value="true" absolute>
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>

    <!-- Поиск товаров -->
    <div
      v-else-if="selectedItem && search.active"
      key="search"
      class="flex-grow-1"
    >
      <StockProductSearch
        :item="selectedItem"
        :selectedId="search.selectedId"
        @close="searchClose"
        @confirm="searchConfirm"
        @select-item="searchSelect"
      />
    </div>

    <!-- Информация о привязке -->
    <div
      v-else-if="selectedItem"
      :key="selectedItem.id"
      class="flex-grow-1 bind-info"
    >
      <div style="position: sticky; top: 90px">
      <div v-if="!settings.bindedId" class="mb-5">
        <!-- Создать элемент -->
        <div class="subtitle-1">Создание элемента:</div>
        <div class="mt-4 text-center" v-if="getStockProductsCreating">
          <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="caption">
            <a class="dashed" @click.prevent="creationSwitch">
              Создать товар
            </a>
          </div>
          <div v-if="creation.active">
            Товар будет создан согласно настройкам привязки его дирректории. Вы
            уверены?
            <div class="mt-2">
              <v-btn
                @click="creationClose"
                class="mr-2"
                color="grey darken-2"
                small
                depressed
                outlined
              >
                Нет
              </v-btn>
              <v-btn
                @click="creationRequest"
                color="error"
                small
                depressed
                outlined
              >
                Да, создать и привязать элемент
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <!-- Привязанный элемент -->
        <div class="subtitle-1">Привязанный элемент:</div>
        <div v-if="bindedItem">
          <StockProductTable :items="[bindedItem]">
            <template #action>
              <v-btn @click="unbindButton" color="error" small depressed>
                Отвязать
              </v-btn>
            </template>
          </StockProductTable>
        </div>
        <div v-else>
          Нет привязки
        </div>
      </div>

      <!-- Возможные элементы привязки -->
      <div class="mb-5">
        <div class="subtitle-1">Возможные элементы привязки:</div>
        <StockProductTable :items="suggestItems">
          <template #action="{ item }">
            <v-btn
              @click="bindButton(item)"
              :color="buttonColor(item)"
              small
              depressed
            >
              {{ buttonText(item) }}
            </v-btn>
          </template>
        </StockProductTable>
      </div>

      <!-- Открыть поиск элементов -->
      <div class="caption">
        <a class="dashed" @click.prevent="searchOpen">
          Поиск товаров
        </a>
      </div>

      <!-- Кнопки сохранения -->
      <v-divider class="mt-4"></v-divider>
      <div>
        <div class="d-flex justify-end flex-column flex-md-row py-4">
          <v-btn
            @click="resetChanges()"
            :disabled="!hasSettingsChanges"
            class="mr-md-2"
            color="error"
            small
            depressed
          >
            Сбросить
          </v-btn>
          <v-btn
            @click="saveChanges()"
            :disabled="!hasSettingsChanges"
            color="primary"
            class="mt-1 mt-md-0"
            small
            depressed
          >
            Сохранить изменения
          </v-btn>
        </div>
      </div>
        </div>
    </div>

    <!-- Лоадер -->
    <div v-else class="flex-grow-1 text-center py-12">
      <div
        class="mt-4 text-center"
        v-if="
          getDistributorProductsFetching ||
            getStockProductsSuggestFetching ||
            getStockProductsFetching
        "
      >
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="title font-weight-light">
        Выберите товар
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import StockProductTable from './StockProductTable'
import StockProductSearch from './StockProductSearch'

export default {
  components: {
    StockProductTable,
    StockProductSearch
  },
  props: {
    selectedItem: Object,
    // Метод загрузки товаров страницы
    fetchDistributorProducts: Function
  },
  data: () => ({
    input: '',
    // Id выбранного элемента
    selectedItemId: null,
    // Настройки привязки
    defaultSettings: {
      bindedId: null
    },
    // Изменения настроек
    changedSettings: {
      bindedId: null
    },
    // Данные для ручного выбора элемента привязки
    search: {
      active: false,
      selectedId: null
    },
    creation: {
      active: false
    }
  }),
  watch: {
    // Устанавливаем значения при смене бренда
    selectedItem: function(selectedItem) {
      // Сбрасываем значения
      if (!selectedItem || selectedItem.id !== this.selectedItemId) {
        this.search.active = false
        this.search.selectedId = null
        this.creation.active = false
      }
      if (selectedItem) {
        this.defaultSettings.bindedId = selectedItem.bind
        this.changedSettings.bindedId = selectedItem.bind
      } else {
        this.defaultSettings.bindedId = null
        this.changedSettings.bindedId = null
      }
      this.selectedItemId = selectedItem ? selectedItem.id : null
    }
  },
  computed: {
    ...mapGetters('distributor_products', ['getDistributorProductsFetching']),
    ...mapGetters('stock_products', [
      'getStockProductsFetching',
      'getStockProductsSuggestFetching',
      'getStockProductById',
      'getStockProductsByIdList',
      'getStockProductsSuggest',
      'getStockProductsCreating'
    ]),
    // Настройки с учетом изменений
    settings: function() {
      return {
        ...this.defaultSettings,
        ...this.changedSettings
      }
    },
    // Наличие изменений
    hasSettingsChanges: function() {
      return !_.isEqual(this.defaultSettings, this.changedSettings)
    },
    // Привязанный элемент
    bindedItem: function() {
      return this.settings.bindedId
        ? this.getStockProductById(this.settings.bindedId)
        : null
    },
    suggestItems: function() {
      const idList = [
        ...(this.getStockProductsSuggest(this.selectedItemId) || [])
      ]
      // Добавляем уже привязанный
      if (this.defaultSettings.bindedId) {
        idList.push(this.defaultSettings.bindedId)
      }
      // Добавляем параметр из измененных настроек
      if (this.changedSettings.bindedId) {
        idList.push(this.changedSettings.bindedId)
      }
      // Добавляем товар из поиска
      if (this.search.selectedId) {
        idList.push(this.search.selectedId)
      }
      return this.getStockProductsByIdList(_.uniq(idList))
    }
  },
  methods: {
    ...mapActions('distributor_products', [
      'actionPatchDistributorProduct',
      'actionBindDistributorProduct'
    ]),
    ...mapActions('stock_products', ['actionCreateDistributorProductInStock']),
    // Открыть ручной выбор
    searchOpen: function() {
      this.search.active = true
    },
    // Закрыть ручной выбор
    searchClose: function() {
      this.search.active = false
    },
    // Событие ручного выбора
    searchSelect: function(item) {
      const { selectedId } = this.search
      this.search.selectedId = !item || selectedId === item.id ? null : item.id
    },
    // Подтверждение ручного выбора
    searchConfirm: function() {
      this.changedSettings.bindedId = this.search.selectedId
      this.searchClose()
    },
    // Открыть форму создания
    creationSwitch: function() {
      this.creation.active = !this.creation.active
    },
    // Закрыть форму создания
    creationClose: function() {
      this.creation.active = false
    },
    // Создание нового элемента
    // По окночанию запроса обновляем выбранный элемент и подгружаем товары для текущей страницы
    creationRequest: function() {
      this.actionCreateDistributorProductInStock(this.selectedItemId)
        .then(item => {
          if (item) {
            this.actionPatchDistributorProduct({
              id: this.selectedItemId,
              data: { bind: item.id }
            }).then(() => {
              this.fetchDistributorProducts()
            })
          }
        })
    },
    // Цвет кнопки
    buttonColor: function(item) {
      return this.settings.bindedId === item.id ? 'success' : 'primary'
    },
    // Текст кнопки
    buttonText: function(item) {
      return this.settings.bindedId === item.id ? 'Выбрано' : 'Выбрать'
    },
    // кнопка привязки
    bindButton: function(item) {
      this.changedSettings.bindedId = item.id
    },
    // Кнопка очистки привязки
    unbindButton: function() {
      this.changedSettings.bindedId = null
    },
    // Сброс всех изменений
    resetChanges: function() {
      this.changedSettings = { ...this.defaultSettings }
    },
    // Сохранение изменений
    saveChanges: function() {
      this.actionBindDistributorProduct({
        id: this.selectedItemId,
        bindId: this.changedSettings.bindedId
      }).then(() => {
        this.fetchDistributorProducts()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bind-info {
  position: relative;

  .empty-message {
    margin-bottom: 2px;
  }
}
</style>
